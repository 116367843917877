import componentIterator from './utils/componentIterator';
import '../patterns/02-ui-components/01-horizontal/horizontal';
import '../patterns/02-ui-components/02-navigation-bar/navigation-bar';
import '../patterns/02-ui-components/04-hero/hero';
import '../patterns/02-ui-components/07-products/products';
import '../patterns/02-ui-components/08-gallery/gallery';
import '../patterns/02-ui-components/10-tiles/tiles';
import '../patterns/02-ui-components/11-story-image/story-image';
import '../patterns/02-ui-components/13-story-video/story-video';
import '../patterns/02-ui-components/18-top-of-page/top-of-page';
import '../patterns/02-ui-components/19-quick-links/quick-links';
import '../patterns/02-ui-components/21-ambassadors/ambassadors';
import '../patterns/02-ui-components/23-static-prospect-box/static-prospect-box';
import '../patterns/02-ui-components/24-floating-prospect-box/floating-prospect-box';
import '../patterns/02-ui-components/25-stories/stories';
import '../patterns/02-ui-components/26-competition/competition';
import '../patterns/02-ui-components/28-filters/filters';
import '../patterns/02-ui-components/30-latest-events/latest-events';
import '../patterns/02-ui-components/32-static-filters/static-filters';
import '../patterns/02-ui-components/37-alpha-academy-video-training/alpha-academy-video-training';
import '../patterns/02-ui-components/39-alpha-academy-video-modal/alpha-academy-video-modal';
import '../patterns/02-ui-components/41-iframe-freehtml/iframe-freehtml';
import '../patterns/02-ui-components/42-columns/columns';
import '../patterns/02-ui-components/43-user-preferences/user-preferences';
import '../patterns/02-ui-components/45-my-gear/my-gear';
import '../patterns/02-ui-components/46-questionnaire/questionnaire';
import '../patterns/02-ui-components/47-popup/popup';
import '../patterns/02-ui-components/48-standalone-cta/standalone-cta';
import '../patterns/02-ui-components/49-influencers/influencers';
import '../patterns/02-ui-components/52-alpha-academy-single-video-modal/alpha-academy-single-video-modal';
import '../patterns/02-ui-components/53-manage-cookie/manage-cookie';
import '../patterns/02-ui-components/54-banner/banner';
import '../patterns/02-ui-components/55-webinar-video/webinar-video';

import '../patterns/_04-reusable-components/01-responsive-image/responsive-image';
import '../patterns/_04-reusable-components/04-content-more/content-more';

componentIterator();
