import Swiper from 'swiper/js/swiper';

export default class ProductsViewModel {
    constructor(module) {
        this.module = module;
        this.swiper = null;
        this.hookSlider();
        this.trackingEvent();
    }

    trackingEvent() {
        const products = this.module.querySelectorAll('.product-thumbnail');

        if (products) {
            products.forEach(product => {

                const productName = product.querySelector('.product-thumbnail__text__title');
                const links = product.querySelectorAll('a');

                if (links) {
                    links.forEach(link => {

                        link.addEventListener('click', () => {

                            const event = new CustomEvent('clickedBuyButton', {
                                detail: {
                                    productName: unescape(productName.innerHTML).trim()
                                }
                            });

                            document.dispatchEvent(event);
                        });
                    });
                }
            });
        }
    }

    hookSlider() {
        this.initSwiper();
        window.addEventListener(
            'resize',
            () => this.initSwiper(),
            this
        );
    }

    destroySwiper() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
            const products = document.querySelector('.products');
            const row = products.querySelector('.row');
            const columns = row.querySelectorAll('.columns');
            const pagination = document.querySelector('.swiper-pagination');

            products.classList.remove('swiper-container');
            row.classList.remove('swiper-wrapper');
            if (columns.length < 3) {
                row.classList.add('swiper-single');
            }
            columns.forEach(column => column.classList.remove('swiper-slide'));
            pagination.style.display = 'none';
        }
    }

    initSwiper() {
        const products = document.querySelector('.products');
        const row = products.querySelector('.row');
        const columns = row.querySelectorAll('.columns');
        if (columns.length < 3) {
            row.classList.add('swiper-single');
        }

        if (window.innerWidth < 768) {
            if (this.swiper === null) {
                // const products = document.querySelector('.products');
                // const row = products.querySelector('.row');
                // const columns = row.querySelectorAll('.columns');
                const pagination = document.querySelector('.swiper-pagination');

                products.classList.add('swiper-container');
                row.classList.add('swiper-wrapper');
                row.classList.remove('swiper-single');
                columns.forEach(column => column.classList.add('swiper-slide'));
                pagination.style.display = 'block';

                this.swiper = new Swiper('.products.swiper-container', {
                    direction: 'horizontal',
                    loop: false,
                    init: true,
                    initialSlide: 0,
                    pagination: {
                        el: '.swiper-pagination'
                    }
                });
            }
        } else {
            this.destroySwiper();
        }
    }

    static init(module) {
        new ProductsViewModel(module);
    }

}

window.SAU = window.SAU || {};
window.SAU.products = ProductsViewModel;
