export default class FreehtmlViewModel {
    constructor(module) {
        this.module = module;
    }

    static init(module) {
        new FreehtmlViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.freehtml = FreehtmlViewModel;
