/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import '../../../../js/utils/polyfill-nodeList-forEach';
import '../../../../js/utils/polyfill-array-from';

import DomHelper from './dom-component';

const defaults = {
    translations: null,
    items: [],
    multi: true,
    display: 'value',
    current: null,
    parent: null,
    parentName: null,
    maxHeight: 0,
    placeholder: 'Select',
    more: '{length} selected',
};

export default class MultiSelect extends DomHelper {
    constructor(element, options = {}) {
        super(element, options, defaults);

        this.options.items = options.items && options.items.length
            ? this._convertItems(options.items)
            : [];

        if (this.options.translations !== null) {
            this.options.translations = options.translations && options.translations.length
                ? this._convertItems(options.translations)
                : [];
        }

        if (options.current && options.current.length) {
            options.current = this._convertItems(options.current);
            this._setSelected(options.current);
        }

        this._renderInit();

        this._setResultMessage();

        this._bindEvents(element);
    }


    /**
     * Bind the delegated dom events
     * @private
     */
    _bindEvents(el) {
        el.addEventListener(
            'click',
            e => {
                if (e.target.classList.contains('si-list') || e.target.tagName === 'UL') return false;
                if (e.target.classList.contains('si-item')) return this._setCurrent(e)._setResultMessage();
                if (!this.dom.el.contains(e.target)) return this.toggle(false);
                return this.toggle();
            },
            this
        );
        document.addEventListener(
            'click',
            e => {
                if (!this.dom.el.contains(e.target)) return this.toggle(false);
                return false;
            },
            this
        );
    }

    /**
     * Get all items in the list
     * @return {Object[]}
     * @public
     */
    getItems() {
        const items = [];
        this.options.items.forEach(item => {
            items.push(item);
        });
        return items;
    }

    /**
     * Get all translations in the list
     * @return {Object[]}
     * @public
     */
    getTranslations() {
        const translations = [];
        this.options.translations.forEach(translation => {
            translations.push(translation);
        });
        return translations;
    }

    /**
     * Resets selection
     * @public
     */
    reset() {
        this._unselectAll();
        this._setResultMessage();
    }

    /**
     * Return the current field value object
     * @param {string} key Only return a specific value from each current item
     * @return {[]|null}
     * @public
     */
    getCurrent(key = '') {
        const items = this.getItems().filter(i => i.selected);
        return !key ? items : items.map(i => i[key]);
    }

    /**
     * Return the current field translation value object
     * @param {string} key Only return a specific value from each current item
     * @return {[]|null}
     * @public
     */
    getCurrentTranslation(key = '') {
        const translations = this.getTranslations().filter(i => i.selected);
        return !key ? translations : translations.map(i => i[key]);
    }

    /**
     * @todo do better: setCurrent, _setCurrent & _setSelected ...
     * @param {*|!object} currents
     * @public
     *
     * 1/10/2020 - Rich's note about translation data
     * > I don't think this method is used anymore, seems to have been replaced by _setCurrent
     * > I've checked dropdown.js and no calls to it there, nor in this file.
     */
    setCurrent(currents) {
        const { items } = this.options;
        const { translations } = this.options;
        const { display } = this.options;

        currents = Array.isArray(currents) ? currents : [currents];
        currents = this._convertItems(currents);

        currents.forEach(current => {
            items.forEach((item, key) => {
                if (item[display] === current[display]) {
                    this.dom.el.querySelector(`.si-item[data-key="${key}"]`).classList.add('si-selected');
                    item.selected = true;
                    item.key = key;
                }
            });
        });

        if (translations !== null) {
            currents.forEach(current => {
                translations.forEach((translation, key) => {
                    if (translation[display] === current[display]) {
                        translation.selected = true;
                        translation.key = key;
                    }
                });
            });
        }


        this._setResultMessage();
    }

    /**
     * Find an item in the list
     * @param {HTMLElement|String|Number} item
     * @return {{}}
     * @public
     */
    findItem(item) {
        const { display } = this.options;
        item = item.nodeName ? item.dataset.value : item;
        return this.options.items.find(i => i[display] === item);
    }

    /**
     *
     * @param {Event} e
     * @param {boolean} trigger
     * @private
     */
    _setOnlyOne(e, trigger = true) {
        const el = e.target;
        const key = parseInt(el.dataset.key, 10);
        const thisItem = this.options.items.get(key);
        const { items } = this.options;
        const { translations } = this.options;

        this._unselectAll();

        items.forEach((item, itemKey) => {
            if (item.value === thisItem.value) {
                item.selected = el.classList.toggle('si-selected');
                item.key = itemKey;
                this.options.items.set(key, item);
                if (trigger) this._trigger('change', item);
            }
        });

        if (translations !== null) {
            // console.log(key);
            // console.log(this.options.translations);
            // getting from items instead of translations because items == labels which is translated
            const thisTranslation = this.options.items.get(key);
            translations.forEach((translation, translationKey) => {
                if (translation.value === thisTranslation.value) {
                    translation.key = translationKey;
                    this.options.translations.set(key, translation);
                }
            });
        }

        return this;
    }

    /**
     *
     * @param {Event} e
     * @param {boolean} trigger
     * @private
     */
    _setCurrent(e, trigger = true) {
        if (this.options.multi) {
            const el = e.target;
            const key = parseInt(el.dataset.key, 10);
            const item = this.options.items.get(key);

            item.selected = el.classList.toggle('si-selected');
            item.key = key;
            this.options.items.set(key, item);

            if (this.options.translations !== null) {
                const translation = this.options.translations.get(key);

                translation.selected = item.selected;
                translation.key = key;
                this.options.translations.set(key, translation);
            }

            if (trigger) this._trigger('change', item);
            return this;
        }
        return this._setOnlyOne(e, trigger);
    }

    /**
     * Loop over the passed array to unselect items
     * @private
     */
    _unselectAll() {
        const { items } = this.options;
        const { translations } = this.options;

        items.forEach((item, key) => {
            item.selected = false;
            item.key = key;
            const lis = this.dom.el.querySelectorAll('.si-item');
            lis.forEach(li => {
                li.classList.remove('si-selected');
            });
        });

        if (translations !== null) {

            translations.forEach((translation, key) => {
                translation.selected = false;
                translation.key = key;
            });
        }
    }

    /**
     * Loop over the passed array to set selected items
     * @param {array} currents
     * @private
     */
    _setSelected(currents) {
        const { items } = this.options;
        const { translations } = this.options;
        const { display } = this.options;

        currents.forEach(current => {
            items.forEach((item, key) => {
                if (item[display] === current[display]) {
                    item.key = key;
                    item.selected = true;
                }
            });

            if (translations !== null) {
                translations.forEach((translation, key) => {
                    if (translation[display] === current[display]) {
                        translation.key = key;
                        translation.selected = true;
                    }
                });
            }
        });
    }

    /**
     * Display selection result message
     * @private
     */
    _setResultMessage() {
        // const selection = (this.options.translations !== null) ? this.getCurrentTranslation() : this.getCurrent();
        const selections = this.getCurrent();
        const { display } = this.options;
        const count = this.getCurrent().length;
        let result = '';
        // console.log(this.getCurrent());

        switch (count) {
        case 1:
            selections.forEach(selection => {
                // console.log(selection.key);
                if (this.options.translations !== null) {
                    const translationLists = this.getTranslations();
                    translationLists.forEach((translation, key) => {
                        if (key === selection.key) {
                            // console.log(translation.value);
                            result = translation.value;
                        }
                    });
                } else {
                    result = selections[0][display];
                }
            });
            break;
        case 0:
            result = this.options.placeholder;
            break;
        default:
            result = /({length})/.test(this.options.more)
                ? `${this.options.placeholder}  ${this.options.more.replace('{length}', count)}`
                : this.options.more;
        }

        this.dom.result.classList[count ? 'add' : 'remove']('si-selection');
        this.dom.result.innerHTML = result.split('_').join(' ');
    }

    /**
     * Make an array of object if needed
     * @todo better 'selected' checking: what if `current` is array of objects
     * @param {Array} items
     * @return {Map<Object>}
     * @private
     */
    _convertItems(items = []) {
        const { display } = this.options;
        const map = new Map();
        let key = 0;

        for (let i = 0; i < items.length; i++) {
            if (typeof items[i] !== 'object') {
                items[i] = { [display]: items[i] };
            }
            map.set(key++, items[i]);
        }

        return map;
    }

    /**
     * Create the HTML upon instantiation
     * @return {Node}
     * @private
     */
    _renderInit() {
        const frag = document.createDocumentFragment();

        this.dom.el.classList.add('si-off', 'si-wrap');
        this.dom.result = frag.appendChild(this._renderResultDiv());

        frag.appendChild(this._renderList());
        return this.dom.el.appendChild(frag);
    }

    /**
     * Create the selection result element
     * @return {HTMLElement}
     * @private
     */
    _renderResultDiv() {
        const el = document.createElement('div');
        el.className = 'si-result';
        return el;
    }

    /**
     * Create the list element
     * @return {HTMLElement}
     * @private
     */
    _renderList() {
        const wrap = document.createElement('div');
        const el = document.createElement('ul');
        const { maxHeight } = this.options;

        wrap.className = 'si-list';

        if (maxHeight) wrap.style.maxHeight = `${maxHeight}px`;

        el.innerHTML = this._renderListItems();

        wrap.appendChild(el);

        return wrap;
    }

    _reRenderList() {
        this.element.innerHTML = this._renderListItems();
    }

    /**
     * Create the list items
     * @return {String}
     * @private
     */
    _renderListItems() {
        const { items, translations, parentName } = this.options;
        const { display } = this.options;
        const listObj = {};
        let listStr = '';
        let selected;

        items.forEach((item, key) => {
            selected = item.selected ? ' si-selected' : '';
            const displayName = item[display].split('_').join(' ');

            if (this.options.translations !== null) {
                const translation = translations.get(key);
                const translationName = translation[display].split('_').join(' ');

                listObj[translationName] = `<li data-parent="${parentName}" class="si-item${selected} ${item[display]}" data-key="${key}" data-slug="${item[display]}">${translationName}</li>`;
            } else {
                listObj[displayName] = `<li data-parent="${parentName}" class="si-item${selected} ${item[display]}" data-key="${key}" data-slug="${item[display]}">${displayName}</li>`;
            }

        });

        // order of dropdowns are in alphabetical order
        Object.keys(listObj).sort().forEach(key => {
            listStr += listObj[key];
        });

        return listStr;
    }
}
