import Swiper from 'swiper/js/swiper';
import '../../../js/utils/polyfill-nodeList-forEach'; // lt IE 12

export default class LatestEventsViewModel {
    constructor(module) {
        this.module = module;
        this.swiper = null;
        this.isSwiping = false;
        this.hookSlider();
    }

    hookSlider() {
        this.initSwiper();
        window.addEventListener(
            'resize',
            () => this.initSwiper(),
            this
        );
    }

    destroySwiper() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
            const row = this.module.querySelector('.latest-events__carouselinner');
            const columns = row.querySelectorAll('.latest-events__inner__item');
            const pagination = this.module.querySelector('.swiper-pagination');

            row.classList.remove('swiper-wrapper');
            columns.forEach(column => column.classList.remove('swiper-slide'));
            pagination.style.display = 'none';
        }
    }

    initSwiper() {
        const swiperContainer = this.module.querySelector('.swiper-container');
        if (swiperContainer) {
            if (window.innerWidth < 768) {
                if (this.swiper === null) {
                    const row = this.module.querySelector('.latest-events__carouselinner');
                    const columns = row.querySelectorAll('.latest-events__inner__item');

                    row.classList.add('swiper-wrapper');
                    columns.forEach(column => column.classList.add('swiper-slide'));
                }

                const pagination = this.module.querySelector('.swiper-pagination');
                pagination.style.display = 'block';

                this.swiper = new Swiper('.latest-events .swiper-container', {
                    direction: 'horizontal',
                    loop: false,
                    init: true,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                    initialSlide: 0,
                    pagination: {
                        el: '.latest-events .swiper-pagination'
                    }
                });
            } else {
                this.destroySwiper();
            }
        }
    }

    static init(module) {
        new LatestEventsViewModel(module);
    }

}

window.SAU = window.SAU || {};
window.SAU.latestEvents = LatestEventsViewModel;
