require('mutationobserver-shim'); // Polyfill for IE10

function runComponent(node) {
    const name = node.getAttribute('data-module');
    window.SAU[name].init(node);
}

export default function componentIterator() {
    const elements = document.querySelectorAll('[data-module]');

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        runComponent(element);
    }
}

window.componentIterator = componentIterator;
