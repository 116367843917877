import addToDataLayer from '../../../js/utils/dataLayer';

export default class ContentMoreViewModel {
    constructor(module) {
        this.module = module;
        this.hookMore();
    }

    hookMore() {
        const moreButton = this.module.querySelector('.content-more__button');
        if (window.innerWidth <= 768) {
            this.module.parentNode.classList.add('hidden-content');
            this.module.classList.add('active');
            if (moreButton) {
                moreButton.addEventListener(
                    'click',
                    () => {
                        addToDataLayer({
                            event: 'moreButton.click',
                            buttonName: unescape(module.innerHTML).trim(),
                            buttonType: this.module.classList.contains('active') ? 'active' : 'in-active',
                        });
                        this.module.parentNode.classList.toggle('hidden-content');
                        this.module.classList.toggle('active');
                    },
                    this
                );
            }
        }
    }

    static init(module) {
        new ContentMoreViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.contentMore = ContentMoreViewModel;
