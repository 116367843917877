import Swiper from 'swiper/js/swiper';
import addToDataLayer from '../../../js/utils/dataLayer';

export default class UserPreferencesViewModel {
    constructor(module) {
        this.module = module;
        this.swipers = [];
        this.trackClickEvent();
        this.hookSliders();
    }

    hookSliders() {
        this.initSwipers();
        window.addEventListener(
            'resize',
            () => this.initSwipers(),
            this
        );
    }

    initSwipers() {
        const swiperContainers = this.module.querySelectorAll('.swiper-container');

        if (swiperContainers) {
            swiperContainers.forEach(container => {
                const row = container.querySelector('.user-preferences-swiper');
                row.classList.add('swiper-wrapper');

                const columns = row.querySelectorAll('.story-col');
                columns.forEach(column => column.classList.add('swiper-slide'));

                const pagination = container.querySelector('.swiper-pagination');

                if (columns.length > 1 && window.innerWidth < 768) {
                    pagination.style.display = 'block';
                } else if (columns.length > 2 && window.innerWidth < 1024) {
                    pagination.style.display = 'block';
                } else {
                    pagination.style.display = 'none';
                }

                const swiper = new Swiper(container, {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    watchOverflow: true,
                    loop: false,
                    pagination: {
                        el: '.swiper-pagination'
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 15
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 15
                        }
                    }
                });
                this.swipers.push(swiper);
            });
        }
    }

    destroySwipers() {
        const swiperContainer = document.querySelectorAll('.swiper-container');
        if (swiperContainer) {
            swiperContainer.forEach((container, i) => {
                if (this.swipers && this.swipers[i]) {
                    this.swipers[i].destroy();
                }
                const row = container.querySelector('.user-preferences-swiper');
                const columns = row.querySelectorAll('.story-col');
                const pagination = container.querySelector('.swiper-pagination');

                row.classList.remove('swiper-wrapper');
                columns.forEach(column => column.classList.remove('swiper-slide'));
                pagination.style.display = 'none';
            });
        }
    }

    trackClickEvent() {
        const link = this.module.querySelector('.edit-link');

        link.addEventListener('click', () => {
            addToDataLayer({
                event: 'user.preference.edit',
                title: 'User Preferences Edit',
                type: 'edit',
            });
        });
    }

    static init(module) {
        new UserPreferencesViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.userPreferences = UserPreferencesViewModel;
