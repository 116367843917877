export default class ResponsiveImageViewModel {
    constructor(module) {
        this.module = module;
        this.imageObserver = null;

        this.hookLazyLoading();
        this.setImageSourceOnResize();

    }

    hookLazyLoading() {
        const image = this.module;
        if ('IntersectionObserver' in window) {
            this.imageObserver = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    this.setImageSource();
                    this.imageObserver.unobserve(image);
                }
            });
            this.imageObserver.observe(image);
        } else {
            this.setImageSource();
        }
    }

    setImageSource() {
        const { body } = document;
        if (body.scrollWidth <= 768 || (body.scrollWidth >= 768 && body.scrollWidth <= 846)) {
            this.screenSize = 'mobile';
        } else if (body.scrollWidth > 768 && body.scrollWidth <= 1024) {
            this.screenSize = 'tablet';
        } else if (body.scrollWidth > 1024) {
            this.screenSize = 'desktop';
        }

        const imgSrc = this.module.getAttribute(`data-src-${this.screenSize}`);
        this.module.setAttribute('src', imgSrc);
    }

    setImageSourceOnResize() {
        window.addEventListener(
            'resize',
            () => this.setImageSource(),
            this
        );
    }

    static init(module) {
        new ResponsiveImageViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.responsiveImage = ResponsiveImageViewModel;
