import Swiper from 'swiper/js/swiper';
import '../../../js/utils/polyfill-nodeList-forEach'; // lt IE 12
import addToDataLayer from '../../../js/utils/dataLayer';

export default class AmbassadorsViewModel {
    constructor(module) {
        this.module = module;
        this.swiper = null;
        this.isSwiping = false;
        this.hookSlider();
        this.trackAmbassadors();
    }

    hookSlider() {
        this.initSwiper();
        window.addEventListener(
            'resize',
            () => this.initSwiper(),
            this
        );
    }

    destroySwiper() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
            const row = this.module.querySelector('.flex');
            const columns = row.querySelectorAll('.ambassadors__item');
            const pagination = this.module.querySelector('.swiper-pagination');
            const swiperContainer = this.module.querySelector('.swiper-container');

            swiperContainer.classList.remove('swiper-container');
            row.classList.remove('swiper-wrapper');
            columns.forEach(column => column.classList.remove('swiper-slide'));
            pagination.style.display = 'none';
        }
    }

    initSwiper() {
        if (window.innerWidth < 768) {
            if (this.swiper === null) {
                const container = this.module.querySelector('.right');
                const row = this.module.querySelector('.flex');
                const columns = row.querySelectorAll('.ambassadors__item');

                container.classList.add('swiper-container');
                row.classList.add('swiper-wrapper');
                columns.forEach(column => column.classList.add('swiper-slide'));
            }

            const pagination = this.module.querySelector('.swiper-pagination');
            pagination.style.display = 'block';

            this.swiper = new Swiper('.ambassadors .right.swiper-container', {
                direction: 'horizontal',
                loop: false,
                init: true,
                slidesPerView: window.innerWidth < 500 ? 2 : 3,
                slidesPerGroup: window.innerWidth < 500 ? 2 : 3,
                spaceBetween: 30,
                initialSlide: 0,
                pagination: {
                    el: '.swiper-pagination'
                }
            });
        } else {
            this.destroySwiper();
        }
    }

    trackAmbassadors() {
        const ambassadors = this.module.querySelectorAll('.ambassadors__item__link');
        if (ambassadors) {
            ambassadors.forEach(ambassador => {
                ambassador.addEventListener('click', () => {
                    addToDataLayer({
                        event: 'link.click',
                        linkName: unescape(ambassador.querySelector('.ambassadors__item__text__headline').innerHTML).trim(),
                        linkType: 'ambassador',
                    });
                });
            });
        }
    }

    static init(module) {
        new AmbassadorsViewModel(module);
    }

}

window.SAU = window.SAU || {};
window.SAU.ambassadors = AmbassadorsViewModel;
