import '../../../js/utils/polyfill-nodeList-forEach'; // lt IE 12

export default class TilesViewModel {
    constructor(module) {
        this.module = module;
        this.resizeTile();
        this.hookResizeTile();
    }

    resizeTile() {
        if (window.innerWidth < 1024) {
            this.cssClass('small');
        } else if (window.innerWidth >= 1024 && window.innerWidth <= 1300) {
            this.cssClass('medium');
        } else {
            this.cssClass('large');
        }
    }

    cssClass(size) {
        this.module.querySelector('.tiles').classList.remove('large');
        this.module.querySelector('.tiles').classList.remove('medium');
        this.module.querySelector('.tiles').classList.remove('small');
        this.module.querySelector('.tiles').classList.add(size);
    }

    hookResizeTile() {
        window.addEventListener(
            'resize',
            () => {
                this.resizeTile();
            },
            this
        );
    }

    static init(module) {
        new TilesViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.tiles = TilesViewModel;
