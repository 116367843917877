/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
import Vimeo from '@vimeo/player';
import Youtube from 'youtube-player';

export default class StoryVideoViewModel {
    constructor(module) {

        document.addEventListener('consent_given', event => {

            this.module = module;
            this.autoplay = !!this.module.dataset.autoplay;
            this.player = this.module.querySelector('.story-video-player');
            this.videoId = this.module.querySelector('.story-video-player').getAttribute('data-video-id');
            this.videoType = this.module.querySelector('.story-video-player').getAttribute('data-video-provider');
            this.videoTitle = this.module.querySelector('.story-video-player').getAttribute('data-title');
            this.cookie = this.module.querySelector('.manage-cookie');

            this.getConsentGiven = () => {
                document.addEventListener('consent_given', e => {

                    if (this.videoType === 'vimeo') {
                        if (e.detail.categories.indexOf('vimeo') > -1) {
                            if (this.cookie) {
                                this.cookie.remove();
                            }
                        }
                    }

                    if (this.videoType === 'youtube') {
                        if (e.detail.categories.indexOf('youtube') > -1) {
                            if (this.cookie) {
                                this.cookie.remove();
                            }
                        }
                    }
                });
            };

            this.bindPlayEvent = () => {
                if (this.videoType === 'vimeo') {
                    this.video.on('play', (e) => {
                        const playerEvent = new CustomEvent('videoStarted', {
                            detail: {
                                videoName: this.player.dataset.title,
                                videoLength: e.duration,
                                player: this.video
                            }
                        });

                        document.dispatchEvent(playerEvent);
                    });
                }
                if (this.videoType === 'youtube') {
                    this.video.on('stateChange', (e) => {
                        if (e.data === 1) {
                            let videoDuration;
                            this.video.getDuration().then(value => {
                                videoDuration = value;
                                const playerEvent = new CustomEvent('videoStarted', {
                                    detail: {
                                        videoName: this.player.dataset.title,
                                        videoLength: videoDuration,
                                        player: this.video
                                    }
                                });
                                if (this.YTPlayEventFired === false || this.YTPlayEventPaused === true) {
                                    document.dispatchEvent(playerEvent);
                                    this.YTPlayEventFired = true;
                                    this.YTPlayEventPaused = false;
                                }
                            });
                        }
                    });
                }
            };

            this.bindPauseEvent = () => {
                if (this.videoType === 'vimeo') {
                    this.video.on('pause', (e) => {
                        const playerEvent = new CustomEvent('videoPaused', {
                            detail: {
                                videoName: this.player.dataset.title,
                                videoLength: e.duration,
                                player: this.video
                            }
                        });

                        if (e.percent > 0) {
                            document.dispatchEvent(playerEvent);
                        }
                    });
                }
                if (this.videoType === 'youtube') {
                    this.video.on('stateChange', (e) => {
                        if (e.data === 2) {
                            let videoDuration;
                            this.video.getDuration().then(value => {
                                videoDuration = value;
                                const playerEvent = new CustomEvent('videoPaused', {
                                    detail: {
                                        videoName: this.player.dataset.title,
                                        videoLength: videoDuration,
                                        player: this.video
                                    }
                                });
                                document.dispatchEvent(playerEvent);
                                this.YTPlayEventPaused = true;
                            });
                        }
                    });
                }
            };

            this.bindCompletedEvent = () => {
                if (this.videoType === 'vimeo') {
                    this.video.on('ended', (e) => {
                        const playerEvent = new CustomEvent('videoCompleted', {
                            detail: {
                                videoName: this.player.dataset.title,
                                videoLength: e.duration,
                                player: this.video
                            }
                        });

                        document.dispatchEvent(playerEvent);
                    });
                }
                if (this.videoType === 'youtube') {
                    this.video.on('stateChange', (e) => {
                        if (e.data === 0) {
                            let videoDuration;
                            this.video.getDuration().then(value => {
                                videoDuration = value;
                                const playerEvent = new CustomEvent('videoCompleted', {
                                    detail: {
                                        videoName: this.player.dataset.title,
                                        videoLength: videoDuration,
                                        player: this.video
                                    }
                                });
                                document.dispatchEvent(playerEvent);
                            });
                        }
                    });
                }
            };

            this.bindMilestoneEvent = () => {
                if (this.videoType === 'vimeo') {
                    const milestones = [25, 50, 75];
                    const milestoneReached = [];
                    this.video.on('timeupdate', (e) => {
                        const percentSeen = e.percent * 100;
                        for (let i = 0; i < milestones.length; i++) {
                            const currentMilestone = milestones[i];
                            if (milestoneReached.length <= i && percentSeen > currentMilestone) {
                                const playerEvent = new CustomEvent('videoProgress', {
                                    detail: {
                                        videoName: this.player.dataset.title,
                                        completion: `${currentMilestone}%`,
                                        videoLength: this.video.duration,
                                        player: this.video
                                    }
                                });

                                document.dispatchEvent(playerEvent);

                                milestoneReached.push('1');
                            }
                        }
                    });
                }
                if (this.videoType === 'youtube') {
                    const milestones = [25, 50, 75];
                    const milestoneReached = [];
                    let videoDuration;
                    let currentTime;
                    this.video.on('stateChange', (e) => {
                        if (e.data === 1) {
                            this.video.getDuration().then(value => {
                                videoDuration = value;
                                const mytimer = setInterval(() => {
                                    this.video.getCurrentTime().then(currentTimevalue => {
                                        currentTime = currentTimevalue;
                                        const percentSeen = (currentTime / videoDuration) * 100;
                                        // console.log(percentSeen);
                                        for (let i = 0; i < milestones.length; i++) {
                                            const currentMilestone = milestones[i];
                                            if (milestoneReached.length <= i && percentSeen > currentMilestone) {
                                                const playerEvent = new CustomEvent('videoProgress', {
                                                    detail: {
                                                        videoName: this.player.dataset.title,
                                                        completion: `${currentMilestone}%`,
                                                        videoLength: videoDuration,
                                                        player: this.video
                                                    }
                                                });
                                                document.dispatchEvent(playerEvent);
                                                milestoneReached.push('1');
                                            }
                                        }
                                    });
                                }, 1000);
                            });
                        }
                    });
                }
            };

            this.bindErrorEvent = () => {
                if (this.videoType === 'vimeo') {
                    this.video.on('error', (e) => {
                        let viewQuality;
                        this.video.getQuality().then(quality => {
                            viewQuality = quality;
                        });
                        const errStr = `vimeo_${e.name}`;
                        const playerEvent = new CustomEvent('videoError', {
                            detail: {
                                errorCode: errStr,
                                videoName: this.player.dataset.title,
                                videoQuality: viewQuality,
                                player: this.video
                            }
                        });
                        document.dispatchEvent(playerEvent);
                    });
                }
                if (this.videoType === 'youtube') {
                    this.video.on('error', (e) => {
                        const errStr = `yt_${e.data}`;
                        let viewQuality;
                        this.video.getPlaybackQuality().then(quality => {
                            viewQuality = quality;
                        });
                        const playerEvent = new CustomEvent('videoError', {
                            detail: {
                                errorCode: errStr,
                                videoName: this.player.dataset.title,
                                videoQuality: viewQuality,
                                player: this.video
                            }
                        });
                        // https://stackoverflow.com/questions/31878905/youtube-iframe-api-why-is-onerror-event-being-called-twice
                        if (this.YTErrorId !== 2) {
                            document.dispatchEvent(playerEvent);
                            this.YTErrorId = e.data;
                        }
                    });
                }
            };

            if (this.videoType === 'vimeo') {
                if (event.detail.categories.indexOf('vimeo') > -1) {

                    const option = {
                        url: this.videoId,
                        responsive: true,
                        autoplay: this.autoplay ? 1 : 0,
                        muted: this.autoplay ? 1 : 0
                    };
                    this.video = new Vimeo(this.player, option);
                    this.video.ready().catch(error => {
                        let viewQuality;
                        this.video.getQuality().then(quality => {
                            viewQuality = quality;
                        });
                        const errStr = `vimeo_404${error.name}`;
                        const playerEvent = new CustomEvent('videoError', {
                            detail: {
                                errorCode: errStr,
                                videoName: option.url,
                                videoQuality: viewQuality,
                                player: this.video
                            }
                        });
                        document.dispatchEvent(playerEvent);
                    });

                    if (this.cookie) {
                        this.cookie.remove();
                    }

                    this.bindPlayEvent();
                    this.bindPauseEvent();
                    this.bindMilestoneEvent();
                    this.bindCompletedEvent();
                    this.bindErrorEvent();
                    this.YTPlayEventFired = false;
                    this.YTPlayEventPaused = false;
                    this.YTErrorId = 0;

                }
            }
            if (this.videoType === 'youtube') {
                if (event.detail.categories.indexOf('youtube') > -1) {
                    this.video = new Youtube(this.player, {
                        width: '100%',
                        height: '100%',
                        videoId: this.videoId,
                        playerVars: {
                            rel: 0,
                            autoplay: this.autoplay ? 1 : 0,
                            mute: this.autoplay ? 1 : 0
                        }
                    });

                    if (this.cookie) {
                        this.cookie.remove();
                    }

                    this.bindPlayEvent();
                    this.bindPauseEvent();
                    this.bindMilestoneEvent();
                    this.bindCompletedEvent();
                    this.bindErrorEvent();
                    this.YTPlayEventFired = false;
                    this.YTPlayEventPaused = false;
                    this.YTErrorId = 0;
                }
            }
        });


        // this.getConsentGiven();

        // var binder = this.bindPlayer;


        /*
        if (this.videoType === 'vimeo') {

        }
        // set opts.related to false;
        if (this.videoType === 'youtube') {

            // this.video.setSize('100%', '100%');
            // this.video.loadVideoById(this.videoId);
        } */


    }


    static init(module) {
        new StoryVideoViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.storyVideo = StoryVideoViewModel;
