export default class WebinarVideo {
    constructor(module) {
        this.module = module;
    }

    static init(module) {
        new WebinarVideo(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.webinarVideo = WebinarVideo;
