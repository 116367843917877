/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import 'whatwg-fetch';

function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export default class FloatingProspectBoxViewModel {
    constructor(module) {
        this.module = module;
        this.form = null;
        this.hookClose();
        this.hookForm();
        this.openModal();
    }

    openModal() {
        this.module.classList.add('floating-prospect-box--show');
    }

    closeModal() {
        if (this.module.classList.contains('floating-prospect-box--show')) {
            this.module.classList.remove('floating-prospect-box--show');
        }
        // createCookie('alphauniverse', 'yes', 60);
    }

    onSubmit(e) {
        e.preventDefault();
        const email = this.form.querySelector('.email').value;
        if (email && emailIsValid(email)) {
            fetch(this.form.action, {
                method: 'POST',
                body: new FormData(this.form)
            }).then(response => {
                if (response.ok) {
                    this.module.classList.remove('floating-prospect-box--error');
                    this.module.classList.remove('floating-prospect-box--other_error');
                    this.module.classList.add('floating-prospect-box--success');
                    const event = new CustomEvent('subscribed', {});
                    document.dispatchEvent(event);
                } else {
                    this.module.classList.add('floating-prospect-box--other_error');
                    this.module.classList.remove('floating-prospect-box--success');
                    this.module.classList.remove('floating-prospect-box--error');
                }
            }).catch(function () {
                const prospectBox = document.querySelector('.floating-prospect-box');
                prospectBox.classList.add('floating-prospect-box--other_error');
                prospectBox.classList.remove('floating-prospect-box--success');
                prospectBox.classList.remove('floating-prospect-box--error');
            });
        } else {
            this.module.classList.add('floating-prospect-box--error');
            this.module.classList.remove('floating-prospect-box--success');
            this.module.classList.remove('floating-prospect-box--other_error');
        }
    }

    hookForm() {
        this.form = this.module.querySelector('form');
        this.form.addEventListener(
            'submit',
            e => this.onSubmit(e),
            this
        );
    }

    hookClose() {
        const close = this.module.querySelector('.floating-prospect-box__close');
        close.addEventListener(
            'click',
            () => this.closeModal(),
            this
        );
    }

    static init(module) {
        new FloatingProspectBoxViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.floatingProspectBox = FloatingProspectBoxViewModel;
