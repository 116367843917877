import Swiper from 'swiper/js/swiper';
import '../../../js/utils/polyfill-nodeList-forEach'; // lt IE 12

export default class AlphaAcademyViewModel {
    constructor(module) {
        this.module = module;
        this.swipers = [];
        this.hookSliders();
        this.hookAccordionLinks();
    }

    hookSliders() {
        this.initSwipers();
        window.addEventListener(
            'resize',
            () => this.initSwipers(),
            this
        );
    }

    destroySwipers() {
        const swiperContainer = document.querySelectorAll('.swiper-container');
        if (swiperContainer) {
            swiperContainer.forEach((container, i) => {
                if (this.swipers && this.swipers[i]) {
                    this.swipers[i].destroy();
                }
                const row = container.querySelector('.alpha-academy-video-training__items__inner');
                const columns = row.querySelectorAll('.alpha-academy-video-training__items__inner__item');
                const pagination = container.querySelector('.swiper-pagination');

                row.classList.remove('swiper-wrapper');
                columns.forEach(column => column.classList.remove('swiper-slide'));
                pagination.style.display = 'none';
            });
        }
    }

    initSwipers() {
        const swiperContainers = this.module.querySelectorAll('.swiper-container');
        if (swiperContainers) {
            if (window.innerWidth < 768) {
                swiperContainers.forEach(container => {
                    const row = container.querySelector('.alpha-academy-video-training__items__inner');
                    row.classList.add('swiper-wrapper');

                    const columns = row.querySelectorAll('.alpha-academy-video-training__items__inner__item');
                    columns.forEach(column => column.classList.add('swiper-slide'));

                    const pagination = container.querySelector('.swiper-pagination');
                    pagination.style.display = columns.length > 2 ? 'block' : 'none';

                    const swiper = new Swiper(container, {
                        direction: 'horizontal',
                        loop: false,
                        init: false,
                        initialSlide: 0,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 24,
                        pagination: {
                            el: '.swiper-pagination'
                        }
                    });
                    this.swipers.push(swiper);
                });
            } else {
                this.destroySwipers();
            }
        }
    }


    hookAccordionLinks() {
        this.moduleLinks = this.module.querySelectorAll('.accordion__header .accordion-title');

        for (let i = 0; i < this.moduleLinks.length; i++) {
            const link = this.moduleLinks[i];

            link.addEventListener(
                'click',
                e => {
                    const parent = e.currentTarget.parentElement.parentElement;
                    const body = parent.querySelector('.accordion__body');

                    AlphaAcademyViewModel.toggleVisibility(body, link);

                    if (this.swipers && this.swipers[i]) {
                        this.swipers[i].init();
                    }
                },
                this
            );
        }
    }

    static toggleVisibility(element, header) {
        if (element.classList.contains('accordion__body--show')) {
            element.classList.remove('accordion__body--show');
            element.setAttribute('aria-expanded', 'false');
        } else {
            element.classList.add('accordion__body--show');
            element.setAttribute('aria-expanded', 'true');
        }
        if (header.parentElement.classList.contains('accordion__header--show')) {
            header.parentElement.classList.remove('accordion__header--show');
        } else {
            header.parentElement.classList.add('accordion__header--show');
        }
    }

    static init(module) {
        new AlphaAcademyViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.alphaAcademy = AlphaAcademyViewModel;
