import Zooming from 'zooming';

export default class StoryImageViewModel {
    constructor(module) {
        this.module = module;
        this.zooming = null;
        this.hookModal();
    }

    hookModal() {
        const image = this.module.querySelector('.story-image__image img');
        this.zooming = new Zooming({
            bgColor: '#000',
            bgOpacity: 0.7,
            scaleExtra: 1.0,
            zIndex: 0
        });
        this.zooming.listen(image);
    }

    static init(module) {
        new StoryImageViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.storyImage = StoryImageViewModel;
