/* eslint-disable arrow-parens */
import '../../../js/utils/polyfill-nodeList-forEach'; // lt IE 12

import Vimeo from '@vimeo/player';

export default class AlphaAcademyVideoModalViewModel {
    constructor(module) {

        this.module = module;
        this.hookModal();
        this.player = this.module.querySelector('.alpha-academy-video-player');
        this.videoId = this.module.querySelector('.alpha-academy-video-player').getAttribute('data-video-id');
        this.videoTitle = this.module.querySelector('.alpha-academy-video-player').getAttribute('title');
        const option = {
            url: this.videoId,
            responsive: true,
            transparent: false
        };
        this.video = new Vimeo(this.player, option);
        this.video.ready().catch(error => {
            let viewQuality;
            this.video.getQuality().then(quality => {
                viewQuality = quality;
            });
            const errStr = `vimeo_404${error.name}`;
            const playerEvent = new CustomEvent('videoError', {
                detail: {
                    errorCode: errStr,
                    videoName: this.player.dataset.title,
                    videoQuality: viewQuality,
                    player: this.video
                }
            });
            console.log(playerEvent);
            document.dispatchEvent(playerEvent);
        });
        // this.unload it true if customer closes modal. this is changed to true again when the video is resumed (play event)
        this.unload = false;
        this.bindPlayEvent();
        this.bindPauseEvent();
        this.bindMilestoneEvent();
        this.bindCompletedEvent();
        AlphaAcademyVideoModalViewModel.hashControl();
    }

    hookModal() {
        const videos = document.querySelectorAll('.alpha-academy-thumb');
        videos.forEach(item => {
            const videoURL = item.querySelector('.alpha-academy-thumb__info').getAttribute('data-video-url');
            const videoTitle = item.querySelector('#videoTitle').innerHTML;
            const videoDescription = item.querySelector('#videoDescription').innerHTML;
            const videoParent = item.parentElement.parentElement;

            item.addEventListener(
                'click',
                () => {
                    this.openModal(videoURL, videoURL, videoTitle, videoDescription, videoParent);
                },
                this
            );
        });

        const playlists = document.querySelectorAll('.alpha-academy-playlist-thumb');
        playlists.forEach(item => {

            const playListItems = item.querySelector('.alpha-academy-thumb_playlist').querySelectorAll('.alpha-academy-thumb');

            if (playListItems.length > 0) {

                const firstVideo = playListItems[0];
                const playListId = item.querySelector('.alpha-academy-thumb__info').getAttribute('data-video-url');
                const videoURL = firstVideo.querySelector('.alpha-academy-thumb__info').getAttribute('data-video-url');
                const videoTitle = firstVideo.querySelector('#videoTitle').innerHTML;
                const videoDescription = firstVideo.querySelector('#videoDescription').innerHTML;
                const videoParent = item.querySelector('.alpha-academy-thumb_playlist');

                const imgs = item.querySelector('.alpha-academy-thumb_playlist').querySelectorAll('img');
                imgs.forEach(img => {
                    const desktopImg = img.getAttribute('data-src-desktop');
                    img.setAttribute('src', desktopImg);
                });

                item.addEventListener(
                    'click',
                    () => {
                        this.openModal(playListId, videoURL, videoTitle, videoDescription, videoParent);

                        const playerEvent = new CustomEvent('playlistClicked', {
                            detail: {
                                playlistTitle: playListId // item.querySelector('.alpha-academy-thumb__info').querySelector('#videoTitle').innerHTML
                            }
                        });

                        document.dispatchEvent(playerEvent);

                    },
                    this
                );

            }
        });


        const close = this.module.querySelector('.alpha-academy-video-modal__close');
        close.addEventListener(
            'click',
            () => this.closeModal(),
            this
        );

        const container = this.module.querySelector('.alpha-academy-video-modal__container');
        this.module.addEventListener(
            'click',
            e => {
                if (!container.contains(e.target)) {
                    this.closeModal();
                }
            },
            this
        );
    }

    innerVideoClick() {
        const videos = document.querySelectorAll('.alpha-academy-video-modal__inner__list .alpha-academy-thumb');
        videos.forEach(item => {
            const videoURL = item.querySelector('.alpha-academy-thumb__info ').getAttribute('data-video-url');
            const videoTitle = item.querySelector('#videoTitle').innerHTML;
            const videoDescription = item.querySelector('#videoDescription').innerHTML;

            item.addEventListener(
                'click',
                () => {
                    this.module.querySelector('#mVideoTitle').innerHTML = videoTitle;
                    this.module.querySelector('#mVideoDescription').innerHTML = videoDescription;
                    this.video.loadVideo(videoURL);
                },
                this
            );
        });
    }

    bindPlayEvent() {
        this.video.on('play', (e) => {
            const playerEvent = new CustomEvent('videoStarted', {
                detail: {
                    videoName: this.module.querySelector('#mVideoTitle').innerHTML,
                    videoLength: e.duration,
                    player: this.video
                }
            });

            document.dispatchEvent(playerEvent);
            this.unload = false;
        });
    }

    bindPauseEvent() {
        this.video.on('pause', (e) => {
            const playerEvent = new CustomEvent('videoPaused', {
                detail: {
                    videoName: this.module.querySelector('#mVideoTitle').innerHTML,
                    videoLength: e.duration,
                    player: this.video
                }
            });

            if (e.percent > 0 && this.unload === false) {
                document.dispatchEvent(playerEvent);
            }
        });
    }

    bindCompletedEvent() {
        this.video.on('ended', (e) => {
            const playerEvent = new CustomEvent('videoCompleted', {
                detail: {
                    videoName: this.module.querySelector('#mVideoTitle').innerHTML,
                    videoLength: e.duration,
                    player: this.video
                }
            });

            document.dispatchEvent(playerEvent);
        });
    }

    bindMilestoneEvent() {
        const milestones = [25, 50, 75];
        const milestoneReached = [];
        this.video.on('timeupdate', (e) => {
            const percentSeen = e.percent * 100;
            for (let i = 0; i < milestones.length; i++) {
                const currentMilestone = milestones[i];
                if (milestoneReached.length <= i && percentSeen > currentMilestone) {
                    const playerEvent = new CustomEvent('videoProgress', {
                        detail: {
                            videoName: this.module.querySelector('#mVideoTitle').innerHTML,
                            completion: `${currentMilestone}%`,
                            videoLength: this.video.duration,
                            player: this.video
                        }
                    });

                    document.dispatchEvent(playerEvent);

                    milestoneReached.push('1');
                }
            }
        });
    }

    bindErrorEvent() {
        this.video.on('error', (e) => {
            let viewQuality;
            this.video.getQuality().then(quality => {
                viewQuality = quality;
            });
            const errStr = `vimeo_${e.name}`;
            const playerEvent = new CustomEvent('videoError', {
                detail: {
                    errorCode: errStr,
                    videoName: this.module.querySelector('#mVideoTitle').innerHTML,
                    videoQuality: viewQuality,
                    player: this.video
                }
            });
            document.dispatchEvent(playerEvent);
        });
    }

    openModal(videoId, videoURL, videoTitle, videoDescription, videoParent) {
        this.video.loadVideo(videoURL);
        // console.log(this.video);
        this.module.querySelector('#mVideoTitle').innerHTML = videoTitle;
        this.module.querySelector('#mVideoDescription').innerHTML = videoDescription;
        this.module.querySelector('.alpha-academy-video-modal__inner__list__inner').innerHTML = videoParent.innerHTML;
        const selected = this.module.querySelector('.alpha-academy-video-modal alpha-academy-video-modal--show');
        if (selected == null) {
            this.module.classList.add('alpha-academy-video-modal--show');
        }
        this.innerVideoClick();

        AlphaAcademyVideoModalViewModel.setPlayerParameter(videoId);
    }

    static setPlayerParameter(id) {
        const url = new URL(window.location);
        url.searchParams.set('p', id);
        window.history.pushState({}, '', url);
    }

    closeModal() {
        if (this.module.classList.contains('alpha-academy-video-modal--show')) {
            this.module.classList.remove('alpha-academy-video-modal--show');
            this.unload = true;
            this.video.unload();

            const url = new URL(window.location);
            url.searchParams.delete('p');
            window.history.pushState({}, '', url);
        }
    }

    static hashControl() {
        const url = new URL(window.location);
        const p = url.searchParams.get('p');

        if (p) {
            const videoThumb = document.querySelector(`[data-video-url='${p}']`);
            if (videoThumb) {

                const imgs = document.querySelectorAll('.alpha-academy-thumb__image-container img');
                imgs.forEach(item => {
                    const desktopImg = item.getAttribute('data-src-desktop');
                    item.setAttribute('src', desktopImg);
                });

                videoThumb.click();
            }
        }
    }

    static init(module) {

        new AlphaAcademyVideoModalViewModel(module);
    }

}

window.SAU = window.SAU || {};
window.SAU.alphaAcademyVideoModal = AlphaAcademyVideoModalViewModel;
