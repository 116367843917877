// import addToDataLayer from '../../../js/utils/dataLayer';

export default class NavigationBarViewModel {
    constructor(module) {
        this.module = module;
        this.stickyElem = module.querySelector('.js-stick-nav');
        this.hookToggleMobileNav();
        this.hookStickyNav();
        this.trackNavClick();
    }

    hookStickyNav() {
        const topBar = document.querySelector('.header__top-bar');
        const moduleHeight = window.scrollY > this.module.getBoundingClientRect().height
            ? this.module.getBoundingClientRect().height
            : this.module.getBoundingClientRect().top;
        const maxTop = topBar && topBar.clientHeight ? topBar.clientHeight : moduleHeight;

        window.addEventListener(
            'scroll',
            () => {
                const scrollHeight = window.pageYOffset;
                if (Math.floor(scrollHeight) >= maxTop) {
                    if (!this.stickyElem.classList.contains('sticky')) {
                        this.stickyElem.classList.add('sticky');
                    }
                } else if (this.stickyElem.classList.contains('sticky')) {
                    this.stickyElem.classList.remove('sticky');
                }
            },
            this
        );
    }

    hookToggleMobileNav() {
        const navigationToggle = this.module.querySelector('.navigation-bar__navigation-toggle');

        navigationToggle.addEventListener(
            'click',
            e => {
                const parent = e.currentTarget.parentElement.parentElement.parentElement;
                NavigationBarViewModel.toggleNavigation(parent, navigationToggle);
            },
            this
        );

        window.addEventListener(
            'resize',
            () => NavigationBarViewModel.closeNavigation(navigationToggle.parentElement.parentElement.parentElement, navigationToggle),
            this
        );
    }

    trackNavClick() {
        const mainLinks = this.module.querySelectorAll('.navigation-bar__menu__links__link');
        if (mainLinks) {
            mainLinks.forEach(mainLink => {
                mainLink.addEventListener('click', () => {

                    const event = new CustomEvent('componentInteraction', {
                        detail: {
                            interactionType: 'navigation-click',
                            componentType: 'alphauniverse-secondary-header',
                            componentID: 'alphauniverse-secondary-header',
                            actionableItemValue: unescape(mainLink.querySelector('a').getAttribute('data-label')).trim()
                        }
                    });
                    console.log(event.detail);
                    document.dispatchEvent(event);

                    // addToDataLayer({
                    //     event: 'link.click',
                    //     linkName: unescape(mainLink.querySelector('a').innerHTML).trim(),
                    //     linkType: 'top nav',
                    // });
                });
            });
        }
    }

    static toggleNavigation(parent, toggle) {
        const isToggled = parent.classList.contains('toggled');
        toggle.setAttribute('aria-expanded', !isToggled);

        if (isToggled) {
            parent.classList.remove('toggled');
        } else {
            parent.classList.add('toggled');
        }
    }

    static closeNavigation(parent, toggle) {
        if (parent.classList.contains('toggled')) {
            parent.classList.remove('toggled');
            toggle.setAttribute('aria-expanded', false);
        }
    }

    static init(module) {
        new NavigationBarViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.navigationBar = NavigationBarViewModel;
