import addToDataLayer from '../../../js/utils/dataLayer';

export default class ColumnsViewModel {
    constructor(module) {
        this.module = module;
        this.trackClickEvent();
    }

    trackClickEvent() {
        const columns = this.module.querySelectorAll('.multi-column');
        if (columns) {
            columns.forEach(column => {
                const links = column.querySelectorAll('.rich-text__cta');
                links.forEach(link => {
                    link.addEventListener('click', () => {
                        addToDataLayer({
                            event: 'banner.click',
                            linkName: unescape(link.getAttribute('data-label')).trim(),
                            linkUrl: link.getAttribute('href'),
                        });
                    });
                });
            });
        }
    }

    static init(module) {
        new ColumnsViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.columns = ColumnsViewModel;
