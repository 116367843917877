const decodeUrlParams = param => {
    const name = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    const ret = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));

    if (param.indexOf('[') !== -1) {
        if (ret.indexOf(',') !== -1) {
            const list = ret.split(',');
            const cleanList = [];
            list.forEach(element => {
                cleanList.push(element.trim());
            });
            return cleanList;
        }
        return ret === '' ? [] : [ret.trim()];
    }

    return ret;
};

export default decodeUrlParams;
