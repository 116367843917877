/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import '../../../js/utils/polyfill-nodeList-forEach';
import decodeUrlParams from '../../../js/utils/decodeUrlParams';

export default class StaticFiltersViewModel {
    constructor(module) {
        this.module = module;
        this.hookDropdown();
    }

    trackFilters() {
        // authorType[]=&type[]=&list-genre[]=&list-lenses[]=&list-cameras[]=
        // tag=[ambassador];genre=[reportage];cameras=[modelA],[modelB];lenses=[modelC],[modelD]
        // console.log(queryString);
        let queryString = '';
        const dropDownParams = this.module.querySelectorAll('.filter');
        dropDownParams.forEach(dropDownParam => {
            const hiddenField = dropDownParam.querySelector('.filter__field');
            const nameParam = hiddenField.getAttribute('name');
            const valueParam = hiddenField.getAttribute('value');
            if (nameParam === 'date') {
                if (valueParam === '0') {
                    queryString += 'date=ThisMonth&';
                } else if (valueParam === '1') {
                    queryString += 'date=NextMonth&';
                } else if (valueParam === '2') {
                    queryString += 'date=Later&';
                } else if (valueParam === 'All') {
                    queryString += 'date=All&';
                } else {
                    queryString += 'date=&';
                }
            } else {
                queryString += `${nameParam}=${valueParam}&`;
            }
        });
        queryString = queryString.slice(0, -1);
        const event = new CustomEvent('filtersUpdated', {
            detail: {
                filters: queryString
            }
        });
        document.dispatchEvent(event);
        // console.log(queryString);
        // console.log(event);
    }

    dropdownEvents(dropDown, e) {
        if (dropDown.classList.contains('closed')) {
            const other = dropDown.parentElement.querySelectorAll('.filter');
            other.forEach(filter => {
                if (!filter.classList.contains('closed')) {
                    filter.classList.add('closed');
                }
            });
            dropDown.classList.remove('closed');

        } else if (!e.target.classList.contains('list')) {
            const value = e.target.getAttribute('data-value');
            if (value) {
                const hiddenField = dropDown.querySelector('.filter__field');
                hiddenField.value = value;
                this.trackFilters();
                const siblings = e.target.parentElement.querySelectorAll('li');
                siblings.forEach(li => {
                    li.classList.remove('selected');
                });
                e.target.classList.add('selected');

                if (window.innerWidth > 768) {
                    dropDown.parentElement.submit();
                }
            }
        } else {
            dropDown.classList.add('closed');
        }
    }

    hookDropdown() {
        const dropDowns = this.module.querySelectorAll('.filter');
        dropDowns.forEach(dropDown => {
            const hidden = dropDown.querySelector('.filter__field');
            const name = hidden.getAttribute('name');
            const ul = dropDown.querySelectorAll('ul li');
            const urlValue = decodeUrlParams(name);
            const placeholder = dropDown.querySelector('.filter .result');
            let optionMarked = false;
            ul.forEach(li => {
                const value = li.getAttribute('data-value');
                if (li.classList.contains('selected') || urlValue === value) {
                    if (!li.classList.contains('selected') && optionMarked === false) {
                        li.classList.add('selected');
                        hidden.value = value;
                        placeholder.textContent = `${placeholder.textContent} (${li.textContent})`;
                        optionMarked = true;
                    }
                }
            });

            dropDown.addEventListener(
                'click',
                e => {
                    e.preventDefault();
                    this.dropdownEvents(dropDown, e);
                },
                this
            );

            dropDown.addEventListener(
                'keydown',
                e => {
                    if ((e.which === 13) || (e.which === 32)) {
                        e.preventDefault();
                        this.dropdownEvents(dropDown, e);
                    }
                    // down arrow
                    if (e.which === 40) {
                        e.preventDefault();
                        const listAll = dropDown.querySelectorAll('li');
                        let hasActive = false;
                        for (let i = 0; i < listAll.length - 1; i++) {
                            if (listAll[i] === document.activeElement) {
                                listAll[i + 1].focus();
                                hasActive = true;
                                break;
                            }
                        }
                        if (!hasActive) {
                            listAll[0].focus();
                        }
                    }
                    // up arrow
                    if (e.which === 38) {
                        e.preventDefault();
                        const listAll = dropDown.querySelectorAll('li');
                        let hasActive = false;
                        for (let i = 1; i < listAll.length; i++) {
                            if (listAll[i] === document.activeElement) {
                                listAll[i - 1].focus();
                                hasActive = true;
                                break;
                            }
                        }
                        if (!hasActive) {
                            listAll[listAll.length - 1].focus();
                        }
                    }

                    if (e.which === 9) {
                        dropDown.classList.add('closed');
                    }
                },
                this
            );

            window.addEventListener(
                'click',
                e => {
                    if (!dropDown.contains(e.target)) {
                        dropDown.classList.add('closed');
                    }
                },
                this
            );


            const applyButton = document.querySelector('.filters__filters-static .filters__filters-advanced__modal__apply');
            if (applyButton) {
                applyButton.addEventListener(
                    'click',
                    () => dropDown.parentElement.submit(),
                    this
                );
            }
        });
        const showButton = this.module.querySelector('.filters__filters-advanced__show');
        if (showButton && window.innerWidth < 768) {
            showButton.addEventListener(
                'click',
                () => this.module.querySelector('.filters__filters-advanced').classList.add('filters__filters-advanced__modal'),
                this
            );
        }

        const closeButton = this.module.querySelector('.filters__filters-advanced__modal__close');
        if (closeButton) {
            closeButton.addEventListener(
                'click',
                () => this.module.querySelector('.filters__filters-advanced').classList.remove('filters__filters-advanced__modal'),
                this
            );
        }
    }

    static init(module) {
        new StaticFiltersViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.staticFilters = StaticFiltersViewModel;
