/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import MultiSelect from '../../_04-reusable-components/02-multi-select/dropdown/dropdown';
import '../../../js/utils/customEvents';
import '../../../js/utils/polyfill-append';
import '../../../js/utils/polyfill-array-from';
import '../../../js/utils/polyfill-nodeList-forEach';
import decodeUrlParams from '../../../js/utils/decodeUrlParams';

export default class FiltersViewModel {
    constructor(module) {
        this.module = module;
        this.remainingFilters = {};
        this.instances = {};
        this.keywords = {};
        this.hasTranslations = false;
        this.translations = {};
        this.current = { sort: [{ value: '' }] };
        const items = this.module.querySelectorAll('.filters__filterable__content');
        items.forEach((item, i) => {
            item.setAttribute('data-index', i + 1);
        });
        this.setCurrentFilters();
        this.initDropdowns();
        this.hookButtons();
        this.hookShowButtonOnResize();
        this.currentButtonSelection = '';
        // this.layoutItems('ascending');
        // this.layoutItems();
    }

    setCurrentFilters() {
        const selector = this.module.querySelector('.filters__filterable');
        if (selector) {
            const filtersList = selector.getAttribute('data-filterBy');
            if (filtersList) {
                const filters = filtersList.split(',');
                if (filters && filters.length && filters.length > 0) {
                    filters.forEach(filter => {
                        this.instances[filter.trim()] = [];
                        this.keywords[filter.trim()] = [];
                        this.translations[filter.trim()] = [];

                        if (this.module.querySelector(`.${filter.trim()} .multi-select`)) {
                            this.current[filter.trim()] = decodeUrlParams(`${filter.trim()}[]`);
                        } else {
                            this.current[filter.trim()] = decodeUrlParams(`${filter.trim()}`);
                        }
                        if (filter.trim() === 'sort' && this.current.sort.length < 1) {
                            this.current.sort = [{ value: '' }];
                        }
                    });
                }
            }
            // console.log(this.current.sort);

            if (decodeUrlParams('authorType') === '') {
                const all = this.module.querySelector('.filters__filters-buttons button.all');
                if (all) {
                    all.classList.add('selected');
                }
            } else {
                const buttons = this.module.querySelectorAll('.filters__filters-buttons button');
                if (buttons) {
                    buttons.forEach(button => {
                        if (button.classList.contains(decodeUrlParams('authorType'))) {
                            button.classList.add('selected');
                            this.instances.authorType = [{ value: decodeUrlParams('authorType'), selected: true }];
                            const { selectedIcon } = button.dataset;
                            if (selectedIcon) button.setAttribute('style', `background-image: url(${selectedIcon})`);
                        }
                    });
                }
            }

            const sortBy = decodeUrlParams('sort[]');
            if (sortBy && sortBy.length > 0) {
                this.layoutItems(sortBy[0]);
            } else {
                const sortAsc = selector.getAttribute('data-sort-asc');
                if (sortAsc) {
                    this.layoutItems(sortAsc);
                }
            }

        }
    }

    pushHistory() {
        const filters = this.getAppliedFilters();
        const filterKeys = Object.keys(filters);
        const urlFilters = {};
        filterKeys.forEach(key => {
            urlFilters[key] = [];
            if (this.module.querySelector(`.${key} .multi-select`)) {
                filters[key].forEach(appliedFilter => {
                    urlFilters[key].push(appliedFilter.value);
                });
            } else if (filters[key][0]) {
                urlFilters[key] = filters[key][0].value;
            }
        });

        const queryString = Object.keys(urlFilters).map(key => {
            if (Array.isArray(urlFilters[key])) {
                return `${key}[]=${urlFilters[key]}`;
            }
            return `${key}=${urlFilters[key]}`;
        }).join('&');

        this.trackFilters(queryString);

        window.history.pushState({}, null, `?${queryString}`);

    }

    trackFilters(queryString) {
        // May need to convert qs into required filter format for tracking
        // check if this is required
        // authorType[]=&type[]=&list-genre[]=&list-lenses[]=&list-cameras[]=
        // tag=[ambassador];genre=[reportage];cameras=[modelA],[modelB];lenses=[modelC],[modelD]
        // console.log(queryString);
        const event = new CustomEvent('filtersUpdated', {
            detail: {
                filters: queryString
            }
        });
        document.dispatchEvent(event);
        // console.log(queryString);
    }

    sortItems(option) {
        const content = document.querySelector('.filters__filterable');
        const items = content.querySelectorAll('.filter-active');
        const sortDesc = content.getAttribute('data-sort-desc');
        const sortAsc = content.getAttribute('data-sort-asc');
        const sortedItems = [].slice.call(items).sort((a, b) => {
            const nameA = a.getAttribute('data-name');
            const nameB = b.getAttribute('data-name');
            const indexA = parseInt(a.getAttribute('data-index'), 10);
            const indexB = parseInt(b.getAttribute('data-index'), 10);
            if (option === sortDesc) {
                if (nameA < nameB) return 1;
                if (nameA > nameB) return -1;
                return indexA - indexB;
            }
            if (option === sortAsc) {
                if (nameA > nameB) return 1;
                if (nameA < nameB) return -1;
                return indexA - indexB;
            }
            return indexA - indexB;
        });
        return sortedItems;
    }

    layoutItems(sortBy) {
        const wrapperElem = document.getElementsByClassName('filters__filterable__wrapper');
        const store = document.getElementsByClassName('filters__filterable__store');
        const itemElems = this.sortItems(sortBy || this.current.sort[0].value);
        const allItemElems = document.querySelectorAll('.filters__filterable__content');
        const totalRows = 4;
        let itemCol = 0;

        // Replacing this line as it's busting IE11
        // leaving this here while we check everything works as required now.
        // store[0].innerHTML = '';
        while (store[0].firstChild) {
            store[0].removeChild(store[0].lastChild);
        }

        allItemElems.forEach(item => {
            store[0].append(item);
        });

        const cols = document.querySelectorAll('.filters__filterable__col');
        [].forEach.call(cols, col => {
            col.parentNode.removeChild(col);
        });

        for (let rowCount = 0; rowCount < totalRows; rowCount++) {
            const newCol = document.createElement('div');
            newCol.className = 'filters__filterable__col';
            if (wrapperElem && wrapperElem.length > 0) {
                wrapperElem[0].appendChild(newCol);
            }
        }

        if (wrapperElem && wrapperElem.length > 0) {
            for (let itemCount = 0; itemCount < itemElems.length; itemCount++) {
                document.getElementsByClassName('filters__filterable__col')[itemCol].appendChild(itemElems[itemCount]);
                if (itemCol < totalRows - 1) {
                    itemCol++;
                } else {
                    itemCol = 0;
                }
            }
        }
    }

    hookShowButtonOnResize() {
        window.addEventListener(
            'resize',
            () => {
                const showButton = this.module.querySelector('.filters__filters-advanced__show');
                if (showButton && window.innerWidth < 768) {
                    showButton.addEventListener(
                        'click',
                        () => this.module.querySelector('.filters__filters-advanced').classList.add('filters__filters-advanced__modal'),
                        this
                    );
                }
            },
            this
        );
    }

    initDropdowns() {
        const results = this.module.querySelectorAll('.filters__filterable__content');
        this.hasTranslations = this.module.querySelector('.filters__filterable').hasAttribute('data-has-translations');

        if (results && results.length > 0) {
            results.forEach(result => {
                Object.keys(this.instances).forEach(instance => {
                    const keywordData = result.getAttribute(`data-${instance}`);

                    if (keywordData) {
                        if ((keywordData.indexOf(',') !== -1 && instance.indexOf('list-') !== -1) || (keywordData.indexOf(',') !== -1 && instance.indexOf('sort') !== -1)) {
                            const arrayData = keywordData.length > 0 ? keywordData.split(',') : [];
                            arrayData.forEach(value => {
                                const valueStr = value.trim().split(' ').join('_');
                                if (valueStr !== '' && this.keywords[instance].indexOf(valueStr) === -1) {
                                    this.keywords[instance].push(valueStr);
                                }
                            });
                        } else if (this.keywords[instance].indexOf(keywordData.split(' ').join('_')) === -1) {
                            this.keywords[instance].push(keywordData.split(' ').join('_'));
                        }
                    }

                    if (this.hasTranslations) {
                        const translationData = result.getAttribute(`data-translations-${instance}`);

                        if ((translationData.indexOf(',') !== -1 && instance.indexOf('list-') !== -1) || (translationData.indexOf(',') !== -1 && instance.indexOf('sort') !== -1)) {
                            const arrayData = translationData.length > 0 ? translationData.split(',') : [];
                            arrayData.forEach(value => {
                                const valueStr = value.trim().split(' ').join('_');
                                // const valueStr = value.trim();
                                if (valueStr !== '' && this.translations[instance].indexOf(valueStr) === -1) {
                                    this.translations[instance].push(valueStr);
                                }
                            });
                        } else if (this.translations[instance].indexOf(translationData.split(' ').join('_')) === -1) {
                            this.translations[instance].push(translationData.split(' ').join('_'));
                        }
                    }
                });
            });
            // console.log(this.keywords);
            // console.log(this.translations);
            this.hookFilterDropdowns();
            this.filterResults();
        }
    }

    // ToDo - Ensure translations are in here
    hookFilterDropdowns() {
        Object.keys(this.instances).forEach(filter => {
            const query = this.module.querySelector(`.${filter.trim()} .multi-select`);
            if (query !== null) {
                const isRadio = query.classList.contains('multi-select--radio');
                const isSort = query.classList.contains('multi-select--sort');
                const selector = this.module.querySelector('.filters__filterable');
                let more = '{length} selected';

                let sortLabels = [];
                const dropdownLabels = {};
                let dropdownStore = [];

                if (selector) {
                    more = selector.getAttribute('data-placeholder');
                    if (isSort) {
                        const sortAsc = selector.getAttribute('data-sort-asc');
                        const sortDesc = selector.getAttribute('data-sort-desc');
                        if (sortAsc && sortDesc) {
                            sortLabels = [sortAsc, sortDesc];
                        }
                    }

                    dropdownStore = selector.getAttribute('data-translation').length > 0 ? selector.getAttribute('data-translation').split('||') : [];
                    dropdownStore.forEach(label => {
                        Object.defineProperty(dropdownLabels, label.split('__')[0].replace(/(\r\n|\n|\r)/gm, ''), {
                            value: label.split('__')[1],
                        });
                    });
                }

                const values = this.keywords[filter];
                const options = {
                    translations: this.hasTranslations ? this.translations[filter] : null,
                    items: isSort ? sortLabels : values,
                    parent: this.module.querySelector(`.filter.${filter.trim()}`),
                    placeholder: dropdownLabels[filter],
                    parentName: filter,
                    current: this.current[filter],
                    multi: !isRadio,
                    more
                };

                this.instances[filter] = new MultiSelect(query, options);
                this.instances[filter].on('change', option => {
                    if (isSort) {
                        this.current[filter] = [{ value: `${option.detail.value}` }];

                        this.layoutItems();
                    }
                    this.filterResults(filter);
                });
            }
        });
    }

    hookButtons() {
        const buttons = this.module.querySelector('.filters__filters-buttons');
        if (buttons) {
            buttons.addEventListener(
                'click',
                e => this.filterAuthorType(e.target),
                this
            );
        }

        const clearButton = this.module.querySelectorAll('.filters__filters-advanced__clear');
        if (clearButton) {
            clearButton.forEach(button => {
                button.addEventListener(
                    'click',
                    () => this.clearFilters(),
                    this
                );
            });
        }

        const applyButton = this.module.querySelector('.filters__filters-advanced__modal__apply');
        if (applyButton) {
            applyButton.addEventListener(
                'click',
                () => {
                    this.module.querySelector('.filters__filters-advanced').classList.remove('filters__filters-advanced__modal');
                    this.pushHistory();
                },
                this
            );
        }

        const closeButton = this.module.querySelector('.filters__filters-advanced__modal__close');
        if (closeButton) {
            closeButton.addEventListener(
                'click',
                () => this.module.querySelector('.filters__filters-advanced').classList.remove('filters__filters-advanced__modal'),
                this
            );
        }

        const showButton = this.module.querySelector('.filters__filters-advanced__show');
        if (showButton && window.innerWidth < 768) {
            showButton.addEventListener(
                'click',
                () => this.module.querySelector('.filters__filters-advanced').classList.add('filters__filters-advanced__modal'),
                this
            );
        }
    }

    filterAuthorType(button) {
        if (button.value) {
            const previousSelected = this.module.querySelector('.filters__filters-buttons button.selected');
            if (previousSelected) {
                const { icon } = previousSelected.dataset;
                previousSelected.classList.remove('selected');
                if (icon) previousSelected.setAttribute('style', `background-image: url(${icon})`);

            }
            const { selectedIcon } = button.dataset;
            button.classList.add('selected');
            if (selectedIcon) button.setAttribute('style', `background-image: url(${selectedIcon})`);
            this.instances.authorType = button.value === 'all' ? [] : [{ value: button.value, selected: true }];
            if (button.value !== this.currentButtonSelection) {
                this.currentButtonSelection = button.value;
                this.filterResults('authorType');
            }
        }
    }

    keepSelectedFilters(parent, keep) {
        const filters = this.module.querySelectorAll('.multi-select:not(.multi-select--radio)');
        if (filters.length > 1) {
            for (let j = 0; j < filters.length; ++j) {
                const ul = filters[j].querySelectorAll('.si-item');
                for (let i = 0; i < ul.length; ++i) {
                    const li = ul[i];
                    if (!li.classList.contains('si-selected') && li.getAttribute('data-parent') !== parent) {
                        li.style.cssText = 'display: none';
                    }
                    for (let ii = 0; ii < li.classList.length; ++ii) {
                        const name = li.classList[ii];
                        if (keep.length === 0 || keep.indexOf(name.split('_').join(' ')) !== -1) {
                            li.style.cssText = 'display: flex';
                        }
                    }
                }
            }
        }

        const remainingFilters = Object.keys(this.remainingFilters);
        remainingFilters.forEach(instance => {
            const s = `.${instance} .multi-select:not(.multi-select--radio) ul`;
            const currentDropdown = this.module.querySelector(s);
            if (currentDropdown !== null) {
                if (this.remainingFilters[instance].length === 0) {
                    const child = document.createElement('li');
                    const label = document.createTextNode('All');
                    child.appendChild(label);
                    child.classList.add('si-empty', 'si-selected');
                    currentDropdown.appendChild(child);
                } else {
                    const child = currentDropdown.querySelector('.si-empty');
                    if (child !== null) {
                        currentDropdown.removeChild(child);
                    }
                }
            }
        });
    }

    getAppliedFilters() {
        const ret = {};
        const inst = Object.keys(this.instances);
        inst.forEach(instance => {
            if (this.module.querySelector(`.${instance} .multi-select`)) {
                ret[instance] = this.instances[instance].getCurrent();
            } else {
                ret[instance] = this.instances[instance];
            }
        });
        return ret;
    }

    clearFilters() {
        const selected = this.module.querySelector('.filters__filters-buttons button.selected');
        if (selected !== null) {
            selected.classList.remove('selected');
            this.module.querySelector('.filters__filters-buttons button.all').classList.add('selected');
            this.currentButtonSelection = '';
        }

        const inst = Object.keys(this.instances);
        inst.forEach(instance => {
            const dropdown = this.module.querySelector(`.${instance} .multi-select`);
            if (dropdown) {
                this.instances[instance].reset();
                const ul = dropdown.querySelector('ul');
                const child = ul.querySelector('.si-empty');
                if (child) {
                    ul.removeChild(child);
                }
            } else {
                this.instances[instance] = [];
            }
        });

        this.current.sort = [{ value: '' }];
        this.filterResults();

        const selector = this.module.querySelector('.filters__filterable');
        const sortAsc = selector.getAttribute('data-sort-asc');
        if (sortAsc) {
            this.layoutItems();
        }
    }

    filterResults(type) {
        const filters = this.getAppliedFilters();

        const results = this.module.querySelectorAll('.filters__filterable__content');

        const filterKeys = Object.keys(filters);

        filterKeys.forEach(instance => {
            this.remainingFilters[instance] = [];
        });

        const keepFilters = [];

        results.forEach(result => {
            const hasMatch = {};
            filterKeys.forEach(key => {
                const value = result.getAttribute(`data-${key}`);
                let values = [];

                if (value !== null) {
                    if (key.indexOf('authorType') !== -1) {
                        values = value.split(' ').join('_');
                    } else if (key.indexOf('list-') !== -1) {
                        values = value.split(' ').join('_');
                    } else {
                        values.push(value.split(' ').join('_'));
                    }
                }

                if (filters[key].length) {
                    filters[key].forEach(appliedFilter => {
                        if ((values.length && values.indexOf(appliedFilter.value) !== -1) || key === 'sort') {
                            hasMatch[key] = true;
                        }
                    });
                }
            });

            let numberOfAppliedFilters = 0;
            filterKeys.forEach(key => {
                if (filters[key].length > 0) {
                    numberOfAppliedFilters++;
                }
            });

            const matches = Object.keys(hasMatch);

            if (numberOfAppliedFilters === 0 || (matches.length > 0 && matches.length === numberOfAppliedFilters)) {
                filterKeys.forEach(instance => {
                    const data = result.getAttribute(`data-${instance}`);
                    if (data) {
                        if (data.indexOf(',') !== -1 && instance.indexOf('list-') !== -1) {
                            const arrayData = data.length > 0 ? data.split(',') : [];

                            arrayData.forEach(value => {
                                if (value !== '' && keepFilters.indexOf(value) === -1) {
                                    keepFilters.push(value);
                                    this.remainingFilters[instance].push(value);
                                }
                            });
                        } else if (keepFilters.indexOf(data) === -1) {
                            keepFilters.push(data);
                            this.remainingFilters[instance].push(data);
                        }
                    }
                });

                result.style.cssText = 'display: block';
                result.classList.add('filter-active');
            } else {
                result.style.cssText = 'display: none';
                result.classList.remove('filter-active');
            }
        });

        this.keepSelectedFilters(type, keepFilters);
        if (window.innerWidth > 768) {
            this.pushHistory();
        }

        if (type === 'sort') {
            const sortBy = decodeUrlParams('sort[]');
            this.layoutItems(sortBy[0]);
        } else {
            this.layoutItems();
        }
    }

    static init(module) {
        new FiltersViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.filters = FiltersViewModel;
