export default class HeroViewModel {
    constructor(module) {
        this.module = module;

        if (this.module.querySelector('img')) {
            this.setImageSource();
            this.setImageSourceOnResize();
        }
    }

    setImageSource() {
        if (window.innerWidth < 768) {
            this.screenSize = 'mobile';
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
            this.screenSize = 'tablet';
        } else if (window.innerWidth > 1024) {
            this.screenSize = 'desktop';
        }
        const img = this.module.querySelector('img');
        img.src = img.getAttribute(`data-src-${this.screenSize}`);
    }

    setImageSourceOnResize() {
        window.addEventListener(
            'resize',
            () => this.setImageSource(),
            this
        );
    }

    static init(module) {
        new HeroViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.hero = HeroViewModel;
