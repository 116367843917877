export default class CompetitionModel {
    constructor(module) {
        this.module = module;
        this.trackClickEvent();
    }

    trackClickEvent() {
        const links = this.module.querySelectorAll('.competition a');
        if (links) {
            links.forEach(link => {

                link.addEventListener('click', () => {

                    const event = new CustomEvent('clickedBanner', {
                        detail: {
                            bannerTitle: unescape(link.getAttribute('data-label')).trim(),
                            bannerType: 'competition'
                        }
                    });
                    document.dispatchEvent(event);

                });

            });
        }
    }

    static init(module) {
        new CompetitionModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.competition = CompetitionModel;
