export default class TopOfPageViewModel {
    constructor(module) {
        this.module = module;
        this.showfterValue = 250;
        this.hookEvents();

        this.animateScroll = (window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame).bind(window);
        this.cancelAnimateScroll = (window.cancelAnimationFrame || window.mozCancelAnimationFrame).bind(window);
    }

    hookEvents() {
        const button = this.module.querySelector('button');
        button.addEventListener(
            'click',
            () => this.scroller(),
            this
        );
        window.addEventListener(
            'scroll',
            () => this.showModule(),
            this
        );
    }

    showModule() {
        if (window.pageYOffset > this.showfterValue) {
            this.module.classList.add('top-of-page--show');
            if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
                this.module.classList.add('top-of-page--bottom');
            } else {
                this.module.classList.remove('top-of-page--bottom');
            }
        } else {
            this.module.classList.remove('top-of-page--show');
        }
    }

    scroller(lastScrollTop) {
        const scrollTop = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
        if (scrollTop > 0) {
            const animation = this.animateScroll(() => this.scroller(scrollTop));
            if (!lastScrollTop || scrollTop < lastScrollTop) {
                window.scrollTo(0, scrollTop - scrollTop / 15);
            } else {
                this.cancelAnimateScroll(animation);
            }
        }
    }

    static init(module) {
        new TopOfPageViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.topOfPage = TopOfPageViewModel;
