/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import 'whatwg-fetch';

function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export default class StaticProspectBoxViewModel {
    constructor(module) {
        this.module = module;
        this.form = null;
        this.hookForm();
    }

    onSubmit(e) {
        e.preventDefault();
        const email = this.form.querySelector('.email').value;
        if (email && emailIsValid(email)) {
            fetch(this.form.action, {
                method: 'POST',
                body: new FormData(this.form)
            }).then(response => {
                if (response.ok) {
                    this.module.classList.remove('static-prospect-box--error');
                    this.module.classList.remove('static-prospect-box--other_error');
                    this.module.classList.add('static-prospect-box--success');
                    const event = new CustomEvent('subscribed', {});
                    document.dispatchEvent(event);
                } else {
                    this.module.classList.add('static-prospect-box--other_error');
                    this.module.classList.remove('static-prospect-box--success');
                    this.module.classList.remove('static-prospect-box--error');
                }
            }).catch(function () {
                const prospectBox = document.querySelector('.static-prospect-box');
                prospectBox.classList.add('static-prospect-box--other_error');
                prospectBox.classList.remove('static-prospect-box--success');
                prospectBox.classList.remove('static-prospect-box--error');
            });
        } else {
            this.module.classList.add('static-prospect-box--error');
            this.module.classList.remove('static-prospect-box--success');
            this.module.classList.remove('static-prospect-box--other_error');
        }
    }

    hookForm() {
        this.form = this.module.querySelector('form');
        this.form.addEventListener(
            'submit',
            e => this.onSubmit(e),
            this
        );
    }

    static init(module) {
        new StaticProspectBoxViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.staticProspectBox = StaticProspectBoxViewModel;
