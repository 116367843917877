import $ from "jquery";

export default class ManageCookieViewModel {
    constructor(module) {
        this.module = module;

        this.addTemplateToVideo();
        this.getConsentGiven();
    }


    addTemplateToVideo() {

        var youtubeTemplate = $(".youtube-manage-cookie");
        var vimeoTemplate = $(".vimeo-manage-cookie");

        document.addEventListener("consent_given", function (event) {
            
            $(".story-video-player").each(function() { 
                var $this =$(this),
                    src = $this.attr("data-video-provider");
    
                if(src.includes("youtube")) {
                    if(!(event.detail.categories.indexOf("youtube") > -1)) {
                        $this.parent().after(youtubeTemplate.clone());
                    }
                }
                if(src.includes("vimeo")){
                    if(!(event.detail.categories.indexOf("vimeo") > -1)) {
                        $this.parent().after(vimeoTemplate.clone());
                    }
                }
            });
            
            $(".gallery-video-player").each(function() { 
                var $this =$(this),
                    src = $this.attr("data-video-provider");
    
                if(src.includes("youtube")){
                    if(!(event.detail.categories.indexOf("youtube") > -1)) {
                        $this.after(youtubeTemplate.clone());
                    }
                }
    
                if(src.includes("vimeo")){
                    if(!(event.detail.categories.indexOf("vimeo") > -1)) {
                        $this.after(vimeoTemplate.clone());
                    }
                }
            });
    
            $(".alpha-academy-single-video-player").each(function(){
                var $this = $(this);

                if(!(event.detail.categories.indexOf("vimeo") > -1)) {
                    $this.after(vimeoTemplate.clone());
                }

            });
    
            $(".alpha-academy-video-player").each(function(){
                var $this = $(this);
                if(!(event.detail.categories.indexOf("vimeo") > -1)) {
                    $this.after(vimeoTemplate.clone());
                }
            });

        });

    }

    getConsentGiven() {
        document.addEventListener("consent_given", function (event) {
            if (event.detail.categories.indexOf("youtube") > -1) {
                $(".story-video-wrapper .youtube-manage-cookie").remove();
                $(".gallery .youtube-manage-cookie").remove();
            }

            if (event.detail.categories.indexOf("vimeo") > -1) {
                $(".story-video-wrapper .vimeo-manage-cookie").remove();
                $(".gallery .vimeo-manage-cookie").remove();
                $(".alpha-academy-single-video-modal__inner__video .vimeo-manage-cookie").remove();
                $(".alpha-academy-video-modal__inner__video .vimeo-manage-cookie").remove();
            }
        });
    }


    static init(module) {
        new ManageCookieViewModel(module);
    }

}

window.SAU = window.SAU || {};
window.SAU.manageCookie = ManageCookieViewModel;

