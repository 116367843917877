
import $ from "jquery";

export default class QuestionnaireViewModel {
    constructor(module) {
        this.module = module;
        this.initQuestionnaire();
        this.submitForm();
    }


    initQuestionnaire() {
        
        $('#showDiv').hide();
        $('.drawer').slideUp(0);


        //$('#selectNumber').hide();
/*
        let c1 = [];
        let c2 = [];
        let count = Math.ceil($('#tblGenres .row').length / 2);
 
        for(var i=0; i < count;i++) { 

            var element = $('#tblGenres .row').eq(i).detach(); 
            $('#tblGenres .column').eq(0).append(element);

        }
        for(i=count; i < $('#tblGenres .row').length;i++) { 
            
            var element = $('#tblGenres .row').eq(i).detach(); 
            $('#tblGenres .column').eq(1).append(element);

        }
*/
        /*
        $('#tblGenres').html('');
        $('#tblGenres').append('<div class="column"></div><div class="column"></div>');
        
        $('#tblGenres .column').eq(0).append(c1);
        $('#tblGenres .column').eq(1).append(c2);*/

                var last, lastFav;
        $('.select-holder').click(function () {
            if ($('.drawer:visible').length < 1) $('.drawer').slideDown();
        });
        $('.select-holder').mouseleave(function () {
            if ($('.drawer:visible').length > 0) $('.drawer').slideUp();
        })

        if ($('#tblGenres input:checked').length > 1) {
            $('#showDiv').show();
        }else{
            $('#showDiv').hide();
        }

        $('#tblGenres input').each(function (index, element) {
           
            if ($(this).prop('checked')) {
                if( $($('#showDiv .drawer').find("#favorite-" + $(this).attr("id"))).length == 0) {
                    var c = $(this).clone();
                    var l = $(this).next().clone();
                    c.attr('id', "favorite-" + c.attr('id'));
                    c.attr('name', c.attr('id'));
                    l.attr('for', c.attr('id'));
                    c.prop('checked', false);
                    c.data('id', $(this).attr('id'));
                    l.data('id', $(this).attr('id'));
                    $('#showDiv .drawer').append(c).append(l).append('<br/>');
                }
            } 

            checkAll();
        });

        $(document).on("change", ".drawer input", function(){
            $(".drawer input").not(this).prop("checked", false);
            checkAll();
        });

        $('#tblGenres input').on("change", function () {
            if ($('#tblGenres input:checked').length > 1) {
                $('#showDiv').show();
            }else{
                $('#showDiv').hide();
            }

            if ($(this).prop('checked')) {
                
                var c = $(this).clone();
                var l = $(this).next().clone();
                c.attr('id', "favorite-" + c.attr('id'));
                c.attr('name', c.attr('id'));
                l.attr('for', c.attr('id'));
                c.prop('checked', false);
                c.data('id', $(this).attr('id'));
                l.data('id', $(this).attr('id'));
                $('#showDiv .drawer').append(c).append(l).append('<br/>');

            } else {
                removeFavorite($(this).attr('id'));
            }
            checkAll();
        });

        $(document).on('change', 'input', function () {
            checkAll();
        });

        function removeFavorite(id) {
            $('#showDiv label').each(function () {
                if ($(this).data('id') == id) {
                    $(this).next().remove();
                    $(this).prev().remove();
                    $(this).remove();
                }
            })
        }

        function checkAll() {
            if ($('#tblGenres input:checked').length > 4) {
                $('#tblGenres input').each(function () {
                    if (!$(this).prop('checked')) {
                        $(this).addClass('passive');
                        $(this).next().addClass('passive');
                    }
                });
            } else {
                $('#tblGenres input, #tblGenres label').removeClass('passive');
            }
            /*if ($('.drawer input:checked').length > 1) {
                $('.drawer input').each(function () {
                    if (!$(this).prop('checked')) {
                        $(this).addClass('passive');
                        $(this).next().addClass('passive');
                    }
                });
            } else {
                $('.drawer input, .drawer label').removeClass('passive');
            }*/
            if ($('#main-interest input:checked').length > 0 && $('#proficiency-level input:checked').length > 0 && $(
                    '#tblGenres input:checked').length > 0) {
                if($('#tblGenres input:checked').length > 1)
                {
                    if($('.drawer input:checked').length > 0) 
                    {
                        $('.rich-text__cta').removeClass('passive');
                    }
                    else
                    {
                        $('.rich-text__cta').addClass('passive');
                    }
                }
                else
                {
                    $('.rich-text__cta').removeClass('passive');
                }

                
            } else {
                $('.rich-text__cta').addClass('passive');
            }

            if($('.drawer input:checked').length > 0) {
    
                $("#selectNumber option").text($('.drawer input:checked').next("label").text());
            } else {
                $("#selectNumber option").text($("#selectNumber").attr("placeholder"));
            }

        }
        

        /*$('#tblGenres input:checked').each(function(){
            $(this).trigger("click");
        });*/


    }

    submitForm() {
        var button = document.getElementsByClassName('alphauniverse-form-submit-button')[0];
        button.addEventListener("click", function(e) {

            var event = new CustomEvent("form.submit", {
                detail: {
                    formName: "Questionaire Form",
                    buttonType: 'questionaire.form.submit',
                }
            });
            document.dispatchEvent(event);

            let redirectLocation = this.href;

            e.preventDefault();

            let obj = {};
            obj["54"] = [];
            obj["55"] = [];
            obj["56"] = [];
            obj["57"] = [];

            // Set Data
            let interest = document.querySelectorAll('.main-interest');   
            for (var i = 0; i < interest.length; i++) {   
                if(interest[i].checked) { 
                    obj["55"].push(interest[i].value);
                }
            } 
            let level = document.querySelectorAll('.proficiency-level');   
            for (var i = 0; i < level.length; i++) {   
                if(level[i].checked) { 
                    obj["54"].push(level[i].value);
                }
            } 
            let genres = document.querySelectorAll('.genres-input');   
            for (var i = 0; i < genres.length; i++) {   
                if(genres[i].checked) { 
                    obj["56"].push(genres[i].value);
                }
            }  
            if(obj["56"].length > 1) {
                let favorites = document.querySelectorAll('.drawer input');
                for (var i = 0; i < favorites.length; i++) {   
                    if(favorites[i].checked) { 
                        obj["57"].push(favorites[i].value);
                    }
                }  
            } else {
                obj["57"] = obj["56"];
            }

            
            
            let xmlhttp = new XMLHttpRequest();
            let url = document.querySelector('#questionnaire_form').getAttribute("action");
            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState == XMLHttpRequest.DONE) {
                    window.location.href = redirectLocation;
                }
            }
            xmlhttp.open("POST", url);
            xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xmlhttp.send(JSON.stringify(obj));

            return false;
            
        }, false);
    }

    GetSelected() {

        //Create an Array.
        let selected = new Array();

        //Reference the Table.
        let tblGenres = document.getElementById("tblGenres");

        //Reference all the CheckBoxes in Table.
        let chks = tblGenres.getElementsByTagName("INPUT");

        // Loop and push the checked CheckBox value in Array.
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                selected.push(chks[i].value);
            }
        }

        //Display the selected CheckBox values.
        if (selected.length > 0) {
            // alert("Selected values: " + selected.join(","));
            let select = document.getElementById("selectNumber");

            for (let i = 0; i < selected.length; i++) {
                let opt = selected[i];
                let el = document.createElement("option");
                el.textContent = opt;
                el.value = opt;
                select.appendChild(el);
            }

        }
    }

    display() {
        let checkInterestRadio = document.querySelector(
            'input[name="main-interest"]:checked');
        console.log(checkInterestRadio.value, "interest");


        let checkProficiencyRadio = document.querySelector(
            'input[name="proficiency-level"]:checked');

        console.log(checkProficiencyRadio.value, "profiency");

        for (let i = 0; i < selected.length; i++) {
            console.log(selected[i])
        }

    }

    static init(module) {
        new QuestionnaireViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.questionnaire = QuestionnaireViewModel;
