import addToDataLayer from '../../../js/utils/dataLayer';

export default class CTAButtonModel {
    constructor(module) {
        this.module = module;
        this.trackClickEvent();
    }

    trackClickEvent() {
        console.log('tracking event');
        const links = this.module.querySelectorAll('.rich-text__inner a');

        console.log(links);

        if (links) {
            links.forEach(link => {

                console.log(link);
                console.log(link.innerHTML);

                link.addEventListener('click', () => {
                    addToDataLayer({
                        event: 'banner.click',
                        bannerTitle: unescape(link.getAttribute('data-label')).trim(),
                        bannerType: 'standalone',
                    });

                    return false;

                });

            });
        }
    }

    static init(module) {
        new CTAButtonModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.ctabutton = CTAButtonModel;
