/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
import Swiper from 'swiper/js/swiper';
import Vimeo from '@vimeo/player';
import Youtube from 'youtube-player';

export default class GalleryViewModel {
    constructor(module) {
        this.module = module;
        this.galleryThumbs = null;
        this.galleryTop = null;
        this.videoTitles = [];
        this.videoObjs = [];
        const videoStorages = this.module.querySelectorAll('.gallery-video-player');
        videoStorages.forEach(videoStorage => {
            this.videoTitles.push(videoStorage.dataset.title);
        });


        document.addEventListener('consent_given', (event) => {
            if (event.detail.categories.indexOf('vimeo') > -1) {
                localStorage.setItem('auVideoGalleryVimeoConsents', 'true');
            }

            if (event.detail.categories.indexOf('youtube') > -1) {
                localStorage.setItem('auVideoGalleryYoutubeConsents', 'true');
            }
        });

        this.videoDivs = this.module.querySelectorAll('.gallery-video-player');
        this.videoDivs.forEach(videoDiv => {
            const videoIdsrc = videoDiv.getAttribute('data-video-id');
            const videoType = videoDiv.getAttribute('data-video-provider');
            const videoTitle = videoDiv.getAttribute('data-title');

            if (videoType === 'vimeo' && localStorage.getItem('auVideoGalleryVimeoConsents') === 'true') {
                const option = {
                    url: videoIdsrc,
                    responsive: true
                };
                const video = new Vimeo(videoDiv, option);
                this.videoObjs.push([videoType, video, videoTitle]);
                video.ready().catch(error => {
                    let viewQuality;
                    video.getQuality().then(quality => {
                        viewQuality = quality;
                    });
                    const errStr = `vimeo_404${error.name}`;
                    const playerEvent = new CustomEvent('videoError', {
                        detail: {
                            errorCode: errStr,
                            videoName: videoTitle,
                            videoQuality: viewQuality,
                            player: video
                        }
                    });
                    document.dispatchEvent(playerEvent);
                });
            }

            if (videoType === 'youtube' && localStorage.getItem('auVideoGalleryVimeoConsents') === 'youtube') {
                const video = new Youtube(videoDiv, {
                    width: '100%',
                    height: '100%',
                    videoId: videoIdsrc,
                    playerVars: {
                        rel: 0
                    }
                });
                this.videoObjs.push([videoType, video, videoTitle]);
            }
        });
        this.hookGallery();
        this.unFocusClick();
        this.hookVideoEvents();
    }

    hookVideoEvents() {
        this.videoObjs.forEach((video) => {

            let YTPlayEventFired = false;
            let YTPlayEventPaused = false;
            let YTErrorId = 0;
            // on play
            if (video[0] === 'vimeo') {
                video[1].on('play', (e) => {
                    const playerEvent = new CustomEvent('videoStarted', {
                        detail: {
                            videoName: video[2],
                            videoLength: e.duration,
                            player: video[1]
                        }
                    });

                    document.dispatchEvent(playerEvent);
                });
            }
            if (video[0] === 'youtube') {
                video[1].on('stateChange', (e) => {
                    if (e.data === 1) {
                        let videoDuration;
                        video[1].getDuration().then(value => {
                            videoDuration = value;
                            const playerEvent = new CustomEvent('videoStarted', {
                                detail: {
                                    videoName: video[2],
                                    videoLength: videoDuration,
                                    player: video[1]
                                }
                            });
                            if (YTPlayEventFired === false || YTPlayEventPaused === true) {
                                document.dispatchEvent(playerEvent);
                                YTPlayEventFired = true;
                                YTPlayEventPaused = false;
                            }
                        });
                    }
                });
            }

            // on pause
            if (video[0] === 'vimeo') {
                video[1].on('pause', (e) => {
                    const playerEvent = new CustomEvent('videoPaused', {
                        detail: {
                            videoName: video[2],
                            videoLength: e.duration,
                            player: video[1]
                        }
                    });
                    if (e.percent > 0) {
                        document.dispatchEvent(playerEvent);
                    }
                });
            }
            if (video[0] === 'youtube') {
                video[1].on('stateChange', (e) => {
                    if (e.data === 2) {
                        let videoDuration;
                        video[1].getDuration().then(value => {
                            videoDuration = value;
                            const playerEvent = new CustomEvent('videoPaused', {
                                detail: {
                                    videoName: video[2],
                                    videoLength: videoDuration,
                                    player: video[1]
                                }
                            });
                            document.dispatchEvent(playerEvent);
                            YTPlayEventPaused = true;
                        });
                    }
                });
            }


            // on complete
            if (video[0] === 'vimeo') {
                video[1].on('ended', (e) => {
                    const playerEvent = new CustomEvent('videoCompleted', {
                        detail: {
                            videoName: video[2],
                            videoLength: e.duration,
                            player: video[1]
                        }
                    });
                    document.dispatchEvent(playerEvent);
                });
            }
            if (video[0] === 'youtube') {
                video[1].on('stateChange', (e) => {
                    if (e.data === 0) {
                        let videoDuration;
                        video[1].getDuration().then(value => {
                            videoDuration = value;
                            const playerEvent = new CustomEvent('videoCompleted', {
                                detail: {
                                    videoName: video[2],
                                    videoLength: videoDuration,
                                    player: video[1]
                                }
                            });
                            document.dispatchEvent(playerEvent);
                        });
                    }
                });
            }


            // milestone
            if (video[0] === 'vimeo') {
                const milestones = [25, 50, 75];
                const milestoneReached = [];
                video[1].on('timeupdate', (e) => {
                    const percentSeen = e.percent * 100;
                    for (let i = 0; i < milestones.length; i++) {
                        const currentMilestone = milestones[i];
                        if (milestoneReached.length <= i && percentSeen > currentMilestone) {
                            const playerEvent = new CustomEvent('videoProgress', {
                                detail: {
                                    videoName: video[2],
                                    completion: `${currentMilestone}%`,
                                    videoLength: video[1].duration,
                                    player: video[1]
                                }
                            });
                            document.dispatchEvent(playerEvent);
                            milestoneReached.push('1');
                        }
                    }
                });
            }
            if (video[0] === 'youtube') {
                const milestones = [25, 50, 75];
                const milestoneReached = [];
                let videoDuration;
                let currentTime;
                video[1].on('stateChange', (e) => {
                    if (e.data === 1) {
                        video[1].getDuration().then(value => {
                            videoDuration = value;
                            const mytimer = setInterval(() => {
                                video[1].getCurrentTime().then(currentTimevalue => {
                                    currentTime = currentTimevalue;
                                    const percentSeen = (currentTime / videoDuration) * 100;
                                    // console.log(percentSeen);
                                    for (let i = 0; i < milestones.length; i++) {
                                        const currentMilestone = milestones[i];
                                        if (milestoneReached.length <= i && percentSeen > currentMilestone) {
                                            const playerEvent = new CustomEvent('videoProgress', {
                                                detail: {
                                                    videoName: video[2],
                                                    completion: `${currentMilestone}%`,
                                                    videoLength: videoDuration,
                                                    player: video[1]
                                                }
                                            });
                                            document.dispatchEvent(playerEvent);
                                            milestoneReached.push('1');
                                        }
                                    }
                                });
                            }, 1000);
                        });
                    }
                });
            }

            // on error
            if (video[0] === 'vimeo') {
                video[1].on('error', (e) => {
                    let viewQuality;
                    video[1].getQuality().then(quality => {
                        viewQuality = quality;
                    });
                    const errStr = `vimeo_${e.name}`;
                    const playerEvent = new CustomEvent('videoError', {
                        detail: {
                            errorCode: errStr,
                            videoName: video[2],
                            videoQuality: viewQuality,
                            player: video[1]
                        }
                    });
                    document.dispatchEvent(playerEvent);
                });
            }
            if (video[0] === 'youtube') {
                video[1].on('error', (e) => {
                    const errStr = `yt_${e.data}`;
                    let viewQuality;
                    video[1].getPlaybackQuality().then(quality => {
                        viewQuality = quality;
                    });
                    const playerEvent = new CustomEvent('videoError', {
                        detail: {
                            errorCode: errStr,
                            videoName: video[2],
                            videoQuality: viewQuality,
                            player: video[1]
                        }
                    });
                    // https://stackoverflow.com/questions/31878905/youtube-iframe-api-why-is-onerror-event-being-called-twice
                    if (YTErrorId !== 2) {
                        document.dispatchEvent(playerEvent);
                        YTErrorId = e.data;
                    }
                });
            }
        });
    }

    hookGallery() {
        const slidesPerView = 15;
        this.galleryThumbs = new Swiper('.thumbs', {
            spaceBetween: 1,
            slidesPerView: 'auto',
            slidesPerGroup: 5,
            loop: false,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.thumbs .swiper-button-next',
                prevEl: '.thumbs .swiper-button-prev',
            },
        });

        const pausePlayingVideos = {
            on: {
                slideChange: () => {
                    this.videoObjs.forEach(video => {
                        if (video[0] === 'youtube') {
                            video[1].stopVideo();
                        } else if (video[0] === 'vimeo') {
                            video[1].unload();
                        }
                    });
                },
            },
        };

        Swiper.use(pausePlayingVideos);

        this.galleryTop = new Swiper('.main-image', {
            spaceBetween: 10,
            loop: false,
            updateOnWindowResize: true,
            effect: 'slide',
            navigation: {
                nextEl: '.main-image-navigation.swiper-button-next',
                prevEl: '.main-image-navigation.swiper-button-prev',
            },
            thumbs: {
                swiper: this.galleryThumbs
            }
        });

        const slides = this.module.querySelectorAll('.thumbs .swiper-slide');
        if (slides.length < slidesPerView && window.innerWidth >= 640) {
            this.module.querySelector('.thumbs .swiper-wrapper').classList.add('centered');
        }
    }

    unFocusClick() {
        const sliderButtons = this.module.querySelectorAll('.swiper-button-white');
        sliderButtons.forEach(button => {

            button.addEventListener('mouseup', e => {
                e.target.blur();
            });
        });
    }

    static init(module) {
        new GalleryViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.gallery = GalleryViewModel;
