import addToDataLayer from '../../../js/utils/dataLayer';

export default class PopupViewModel {
    constructor(module) {
        this.module = module;
        PopupViewModel.initPopup();
    }

    static initPopup() {
        const modal = document.getElementById('myModal');

        if (modal !== null) {
            const modalDisplay = PopupViewModel.getCookie('modalDisplay');
            // const modalPage = window.location.pathname;

            // modal.style.opacity = '0';

            const span = document.getElementsByClassName('procced-button')[0];

            span.onclick = () => {
                modal.style.display = 'none';

                addToDataLayer({
                    event: 'popup',
                    linkName: 'Popup',
                    linkUrl: '/alphauniverse/my-alpha-universe',
                });

                PopupViewModel.setCookie('modalDisplay', true, 365);
                window.location.href = '/alphauniverse/my-alpha-universe';
            };

            const closeElements = document.querySelectorAll('.close');

            closeElements.forEach(close => {

                close.addEventListener('click', () => {
                    modal.style.display = 'none';

                    addToDataLayer({
                        event: 'popup',
                        linkName: 'Popup',
                        linkType: 'popup.close',
                    });

                });
            });


            const myAlphaUniverseLinks = document.querySelectorAll('#alphauniverse [href="/alphauniverse/my-alpha-universe"]');

            myAlphaUniverseLinks.forEach(myAlphaUniverseLink => {
                // eslint-disable-next-line consistent-return
                myAlphaUniverseLink.addEventListener('click', e => {
                    addToDataLayer({
                        bannerType: 'my-au',
                        bannerTitle: 'my-au'
                    });

                    if (!modalDisplay) {
                        e.preventDefault();
                        modal.style.display = 'block';

                        addToDataLayer({
                            event: 'popup',
                            linkName: 'My Alpha Universe',
                            linkType: 'popup.open',
                        });

                        return false;
                    }

                });

                if (!modalDisplay) {
                    // eslint-disable-next-line no-param-reassign, no-script-url
                    myAlphaUniverseLink.href = 'javascript:;';
                }
            });
            modal.style.display = 'none';
        }
    }

    static setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    static getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    static init(module) {
        new PopupViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.popup = PopupViewModel;
