import Swiper from 'swiper/js/swiper';
import addToDataLayer from '../../../js/utils/dataLayer';

export default class MyGearViewModel {
    constructor(module) {
        this.module = module;
        MyGearViewModel.initSwiper();
        this.trackClickEvent();
    }

    trackClickEvent() {
        const products = this.module.querySelectorAll('.swiper-slide');
        if (products) {
            products.forEach(product => {
                const link = product.querySelector('.product-link');

                link.addEventListener('click', () => {
                    addToDataLayer({
                        event: 'link.click',
                        linkName: unescape(link.innerHTML).trim(),
                        linkType: 'mygear.product',
                    });
                });
            });
        }
    }

    static initSwiper() {
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper('.swiper', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 5,
            spaceBetween: 10,
            loop: false,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                900: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 10
                }
            }
        });
    }

    static init(module) {
        new MyGearViewModel(module);
    }
}

window.SAU = window.SAU || {};
window.SAU.myGear = MyGearViewModel;
