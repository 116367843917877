import Swiper from 'swiper/js/swiper';
import '../../../js/utils/polyfill-nodeList-forEach'; // lt IE 12
import addToDataLayer from '../../../js/utils/dataLayer';

export default class StoriesViewModel {
    constructor(module) {
        this.module = module;
        this.swiper = null;
        this.hookSlider();
        this.trackThumbnailClick();
    }

    hookSlider() {
        this.initSwiper();
        window.addEventListener(
            'resize',
            () => this.initSwiper(),
            this
        );
    }

    destroySwiper() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
            const row = this.module.querySelector('.flex');
            const columns = row.querySelectorAll('.stories__item');
            const pagination = this.module.querySelector('.swiper-pagination');

            row.classList.remove('swiper-wrapper');
            columns.forEach(column => column.classList.remove('swiper-slide'));
            pagination.style.display = 'none';

            const portraits = this.module.querySelectorAll('.stories-thumbnail--portrait');
            portraits.forEach(portrait => {
                portrait.style.cssText = ''; // eslint-disable-line no-param-reassign
            });
        }
    }

    initSwiper() {
        if (window.innerWidth < 1024) {
            this.module.querySelector('.flex').style.cssText = '';

            if (this.swiper === null) {
                const row = this.module.querySelector('.flex');
                const columns = row.querySelectorAll('.stories__item');

                row.classList.add('swiper-wrapper');
                columns.forEach(column => column.classList.add('swiper-slide'));
            }

            const pagination = this.module.querySelector('.swiper-pagination');
            pagination.style.display = 'block';

            this.swiper = new Swiper('.stories .swiper-container', {
                direction: 'horizontal',
                loop: false,
                init: true,
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 0,
                initialSlide: 0,
                pagination: {
                    el: '.stories .swiper-pagination'
                }
            });

            const portraits = this.module.querySelectorAll('.stories-thumbnail--portrait');
            portraits.forEach(portrait => {
                portrait.style.cssText = `width: ${window.innerWidth - 40}px; height: ${window.innerWidth - 40}px`; // eslint-disable-line no-param-reassign
            });
        } else if (window.innerWidth >= 1024 && window.innerWidth <= 1300) {
            this.destroySwiper();
            this.module.querySelector('.flex').style.cssText = 'width: 119%; position: relative; left: -9%; transform: scale(0.83);';
        } else {
            this.destroySwiper();
            this.module.querySelector('.flex').style.cssText = '';
        }
    }

    trackThumbnailClick() {
        const thumbnails = this.module.querySelectorAll('.stories-thumbnail');
        if (thumbnails) {
            thumbnails.forEach(thumbnail => {
                thumbnail.addEventListener('click', () => {
                    addToDataLayer({
                        event: 'link.click',
                        linkName: unescape(thumbnail.querySelector('.stories-thumbnail__text__title').innerHTML).trim(),
                        linkType: 'thumbnail',
                    });
                });
            });
        }
    }

    static init(module) {
        new StoriesViewModel(module);
    }

}

window.SAU = window.SAU || {};
window.SAU.stories = StoriesViewModel;
